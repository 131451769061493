import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import associateApi from '../../../api/associate/associateApi';
import { IAttachment, IAttachmentResponse } from '../../../api/interfaces';
import { notifySuccess } from '../../../api/axios';
import { IAttachmentState } from '../interfaces';

export const uploadAttachment = createAsyncThunk(
    'associate/uploadAttachment',
    async (
        { attachment }: { attachment: IAttachment },
        { rejectWithValue },
    ) => {
        try {
            const response: IAttachmentResponse =
                await associateApi.uploadAttachment(attachment);

            const message: string = i18n.t('Common:filesUploadedSuccessfully');
            notifySuccess(message);

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

const initialState: IAttachmentState = {
    loading: false,
    status: APIStatusOK,
    attachmentId: null,
};

const uploadAttachmentSlice = createSlice({
    name: 'uploadAttachment',
    initialState,
    reducers: {
        resetAttachment: state => {
            state = initialState;
        },
    },
    extraReducers: builder => {
        builder.addCase(uploadAttachment.pending, state => {
            state.loading = true;
            state.status = APIStatusOK;
            state.attachmentId = null;
        });
        builder.addCase(uploadAttachment.fulfilled, (state, action) => {
            state.loading = false;
            state.attachmentId = action.payload.attachmentId;
        });
        builder.addCase(uploadAttachment.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

export const { resetAttachment } = uploadAttachmentSlice.actions;

export default uploadAttachmentSlice.reducer;
