import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { signOutUser } from '../../../../services/auth';

export const UserProfile: FC<{
    isMobileView?: boolean;
}> = props => {
    const navigate = useNavigate();
    const roles = JSON.parse(
        sessionStorage.getItem('userRole') ??
            localStorage.getItem('userRole') ??
            '[]',
    );
    const { t } = useTranslation();

    return (
        <Dropdown>
            {props.isMobileView ? (
                <Dropdown.Toggle className="profile-dropdown-title">
                    {t('LB_Common:myProfile')}
                </Dropdown.Toggle>
            ) : (
                <Dropdown.Toggle className="user-profile-toggler">
                    <FontAwesomeIcon icon={faUser} className="fa-circle" />
                </Dropdown.Toggle>
            )}
            <Dropdown.Menu
                className={
                    props.isMobileView
                        ? 'profile-dropdown-menu'
                        : 'dropdown-menu'
                }
            >
                {roles &&
                    roles.map((value: string, index: number) => (
                        <span key={index}>
                            <Dropdown.Item disabled key={index}>
                                {value}
                            </Dropdown.Item>
                            <hr />
                        </span>
                    ))}
                <Dropdown.Item onClick={() => signOutUser(navigate)}>
                    {t('Login:logout')}
                </Dropdown.Item>
                <hr />
                <Dropdown.Item disabled>{`DM v ${
                    process.env.REACT_APP_VERSION || '0.0.0.0'
                }`}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
