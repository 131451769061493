import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { ITrailerDetailResponse } from '../../../api/trailer/interface/ITrailerDetailResponse';
import { trailerApi } from '../../../api/trailer/trailerApi';

export const fetchTrailerList = createAsyncThunk(
    'fetchTrailerList',
    async (
        {
            limit,
            offset,
            handleCanCallApi,
        }: {
            limit?: number;
            offset?: number;
            handleCanCallApi?: (response: ITrailerDetailResponse[]) => void;
        },
        { rejectWithValue },
    ) => {
        try {
            const response = await trailerApi.fetchTrailerList(limit, offset);
            handleCanCallApi && handleCanCallApi(response);
            return response;
        } catch {
            return rejectWithValue('error');
        }
    },
);

interface ITrailerListInitialState {
    loading: boolean;
    trailerList: ITrailerDetailResponse[];
    status: APIStatus;
}

const initialState: ITrailerListInitialState = {
    loading: false,
    trailerList: [],
    status: APIStatusOK,
};

export const fetchTrailerListSlice = createSlice({
    name: 'fetchTrailerList',
    initialState,
    reducers: {
        setTrailerList: (
            state,
            action: PayloadAction<ITrailerDetailResponse[]>,
        ) => {
            state.trailerList = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchTrailerList.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchTrailerList.fulfilled, (state, action) => {
            state.loading = false;
            state.trailerList =
                (action.meta.arg.offset ? action.meta.arg.offset : 0) > 0
                    ? [...state.trailerList, ...action.payload]
                    : action.payload;
        });
        builder.addCase(fetchTrailerList.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

export const { setTrailerList } = fetchTrailerListSlice.actions;

const TrailerListReducer = fetchTrailerListSlice.reducer;
export default TrailerListReducer;
