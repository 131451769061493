import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LatLngLiteral } from '../interface';
import { ADDRESS_AUTOCOMPLETE_INITIAL_STATE } from './initalState';

export const addressAutocompleteSlice = createSlice({
    name: 'addressAutocomplete',
    initialState: ADDRESS_AUTOCOMPLETE_INITIAL_STATE,
    reducers: {
        setAutocompleteAddress: (state, action: PayloadAction<string>) => {
            state.autocompleteAddress = action.payload;
        },
        setAutocompleteCoords: (
            state,
            action: PayloadAction<LatLngLiteral | null>,
        ) => {
            state.autocompleteCoords = action.payload;
        },
        setAddressInfo: (
            state,
            action: PayloadAction<Record<string, string>>,
        ) => {
            state.addressInfo = action.payload;
        },
        setPreviousAutocompleteAddress: (
            state,
            action: PayloadAction<string>,
        ) => {
            state.previousAutoCompleteAddress = action.payload;
        },
        setIsFilledFromAddressAutocomplete: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.isFilledFromAddressAutocomplete = action.payload;
        },
        setResetValue: (state, action: PayloadAction<boolean>) => {
            state.resetValue = action.payload;
        },
        setResetSuggestions: (state, action: PayloadAction<boolean>) => {
            state.resetSuggestions = action.payload;
        },
        resetAddressAutocompleteStates: state => {
            state.autocompleteAddress =
                ADDRESS_AUTOCOMPLETE_INITIAL_STATE.autocompleteAddress;
            state.autocompleteCoords =
                ADDRESS_AUTOCOMPLETE_INITIAL_STATE.autocompleteCoords;
            state.addressInfo = ADDRESS_AUTOCOMPLETE_INITIAL_STATE.addressInfo;
            state.isFilledFromAddressAutocomplete =
                ADDRESS_AUTOCOMPLETE_INITIAL_STATE.isFilledFromAddressAutocomplete;
        },
    },
});

export const {
    setAutocompleteAddress,
    setAutocompleteCoords,
    setAddressInfo,
    setIsFilledFromAddressAutocomplete,
    setResetValue,
    setResetSuggestions,
    resetAddressAutocompleteStates,
    setPreviousAutocompleteAddress,
} = addressAutocompleteSlice.actions;

const AddressAutocompleteReducer = addressAutocompleteSlice.reducer;
export default AddressAutocompleteReducer;
