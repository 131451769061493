import { LatLngLiteral } from '../interface';

interface IAddressAutoCompleteInitialState {
    autocompleteAddress: string;
    autocompleteCoords: LatLngLiteral | null;
    addressInfo: Record<string, string>;
    isFilledFromAddressAutocomplete: boolean;
    resetValue: boolean;
    resetSuggestions: boolean;
    previousAutoCompleteAddress: string;
}

export const ADDRESS_AUTOCOMPLETE_INITIAL_STATE: IAddressAutoCompleteInitialState =
    {
        autocompleteAddress: '',
        autocompleteCoords: null,
        addressInfo: {},
        isFilledFromAddressAutocomplete: false,
        resetValue: false,
        resetSuggestions: false,
        previousAutoCompleteAddress: '',
    };
