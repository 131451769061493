import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISingleValueSelect } from '../../utils/interfaces';
import { CARGO_DETAILS_INITIAL_STATE, CARGO_ITEM } from './initialState';
import { ICargoDetails } from './interfaces/ICargoDetails';
import { ICargoLocation } from './interfaces/ICargoLocation';

export const cargoSlice = createSlice({
    name: 'cargo',
    initialState: CARGO_DETAILS_INITIAL_STATE,
    reducers: {
        setCargos: (state, action: PayloadAction<ICargoDetails[]>) => {
            state.cargos = action.payload;
        },
        setIsApplyRateToAllChecked: (state, action: PayloadAction<boolean>) => {
            state.isApplyRateToAllChecked = action.payload;
        },
        addCargoLine: state => {
            state.cargos = [...state.cargos, CARGO_ITEM];
        },
        deleteCargoLine: (state, action: PayloadAction<number>) => {
            state.cargos = [
                ...state.cargos.filter(
                    (_, cargoIndex: number) => cargoIndex !== action.payload,
                ),
            ];
        },
        setCargoPickups: (
            state,
            action: PayloadAction<{
                index: number;
                cargoPickups: Partial<ICargoLocation>[];
            }>,
        ) => {
            const { index, cargoPickups } = action.payload;
            state.cargos[index].cargoPickups = cargoPickups;
        },
        setCargoDropoffs: (
            state,
            action: PayloadAction<{
                index: number;
                cargoDropoffs: Partial<ICargoLocation>[];
            }>,
        ) => {
            const { index, cargoDropoffs } = action.payload;
            state.cargos[index].cargoDropoffs = cargoDropoffs;
        },
        setCurrentActiveCargoLocation: (
            state,
            action: PayloadAction<{
                cargoIndex: number | null;
                name?: keyof Partial<ICargoLocation> | null;
            }>,
        ) => {
            state.currentActiveCargoLocation = action.payload;
        },
        setCargoDetailAtIndex: (
            state,
            action: PayloadAction<{
                cargoIndex: number;
                name: keyof ICargoDetails;
                value: boolean | number | string | ISingleValueSelect;
            }>,
        ) => {
            const { cargoIndex, name, value } = action.payload;
            state.cargos[cargoIndex] = {
                ...state.cargos[cargoIndex],
                [name]: value,
            };
        },
        setCargoDetailsAtIndex: (
            state,
            action: PayloadAction<{
                index: number;
                cargoDetails: Partial<ICargoDetails>;
            }>,
        ) => {
            const { index, cargoDetails } = action.payload;
            state.cargos[index] = {
                ...state.cargos[index],
                ...cargoDetails,
            };
        },
        setCargoPickupsAtIndex: (
            state,
            action: PayloadAction<{
                cargoIndex: number;
                pickupIndex: number;
                cargoPickup: Partial<ICargoLocation>;
            }>,
        ) => {
            const { cargoIndex, pickupIndex, cargoPickup } = action.payload;
            state.cargos[cargoIndex].cargoPickups[pickupIndex] = cargoPickup;
            state.cargos[cargoIndex].isCargoAccordionActive = true;
        },
        setCargoDropoffsAtIndex: (
            state,
            action: PayloadAction<{
                cargoIndex: number;
                dropoffIndex: number;
                cargoDropoff: Partial<ICargoLocation>;
            }>,
        ) => {
            const { cargoIndex, dropoffIndex, cargoDropoff } = action.payload;
            state.cargos[cargoIndex].cargoDropoffs[dropoffIndex] = cargoDropoff;
            state.cargos[cargoIndex].isCargoAccordionActive = true;
        },
        setEstTotalRateAtIndex: (
            state,
            action: PayloadAction<{
                index: number;
                estTotalRate: string;
            }>,
        ) => {
            const { index, estTotalRate } = action.payload;
            if (state.cargos[index]) {
                state.cargos[index].estTotalRateInUSD = estTotalRate;
            }
        },
        setActualTotalRateAtIndex: (
            state,
            action: PayloadAction<{
                index: number;
                actualTotalRate: string;
            }>,
        ) => {
            const { index, actualTotalRate } = action.payload;
            if (state.cargos[index]) {
                state.cargos[index].actualTotalRateInUSD = actualTotalRate;
            }
        },
        setEstimatedTotalRate: (state, action: PayloadAction<string>) => {
            state.estimatedTotalRate = action.payload;
        },
        setActualTotalRate: (state, action: PayloadAction<string>) => {
            state.actualTotalRate = action.payload;
        },
        resetCargoDetailsState: state => {
            state.cargos = CARGO_DETAILS_INITIAL_STATE.cargos;
            state.estimatedTotalRate =
                CARGO_DETAILS_INITIAL_STATE.estimatedTotalRate;
            state.actualTotalRate = CARGO_DETAILS_INITIAL_STATE.actualTotalRate;
            state.draftShipmentCargos =
                CARGO_DETAILS_INITIAL_STATE.draftShipmentCargos;
            state.currentActiveCargoLocation =
                CARGO_DETAILS_INITIAL_STATE.currentActiveCargoLocation;
            state.createdCommodityDetails =
                CARGO_DETAILS_INITIAL_STATE.createdCommodityDetails;
        },
        resetAssignedDriverCargoDetailsState: state => {
            state.cargos = CARGO_DETAILS_INITIAL_STATE.cargos;
            state.estimatedTotalRate =
                CARGO_DETAILS_INITIAL_STATE.estimatedTotalRate;
            state.actualTotalRate = CARGO_DETAILS_INITIAL_STATE.actualTotalRate;
            state.currentActiveCargoLocation =
                CARGO_DETAILS_INITIAL_STATE.currentActiveCargoLocation;
            state.createdCommodityDetails =
                CARGO_DETAILS_INITIAL_STATE.createdCommodityDetails;
        },
        setShowCommodityModal: (state, action: PayloadAction<boolean>) => {
            state.showCommodityModal = action.payload;
        },
        setCreatedCommodityDetails: (
            state,
            action: PayloadAction<{
                id: number | null;
                index: number | null;
            }>,
        ) => {
            state.createdCommodityDetails = action.payload;
        },
        setShowReverifyRateModal: (state, action: PayloadAction<boolean>) => {
            state.showReverifyRateModal = action.payload;
        },
        setReVerifyCommodityTypes: (
            state,
            action: PayloadAction<{
                index: number;
                commodityType: string;
            }>,
        ) => {
            const { index, commodityType } = action.payload;
            state.reVerifyCommodityTypes[index] = commodityType;
        },
        setDraftShipmentCargos: (
            state,
            action: PayloadAction<ICargoDetails[]>,
        ) => {
            state.draftShipmentCargos = action.payload;
        },
    },
});

export const {
    setCargos,
    addCargoLine,
    deleteCargoLine,
    setCargoPickups,
    setCargoDropoffs,
    setCargoDetailAtIndex,
    setCargoDetailsAtIndex,
    setCargoPickupsAtIndex,
    setCargoDropoffsAtIndex,
    setCurrentActiveCargoLocation,
    setEstTotalRateAtIndex,
    setEstimatedTotalRate,
    setActualTotalRate,
    resetCargoDetailsState,
    resetAssignedDriverCargoDetailsState,
    setShowCommodityModal,
    setCreatedCommodityDetails,
    setShowReverifyRateModal,
    setActualTotalRateAtIndex,
    setReVerifyCommodityTypes,
    setDraftShipmentCargos,
    setIsApplyRateToAllChecked,
} = cargoSlice.actions;

const CargoDetailsReducer = cargoSlice.reducer;
export default CargoDetailsReducer;
