export const AttachmentExpiryTypes = {
    expired: 'Expired',
    expiringSoon: 'ExpiringSoon',
};

export const AttachmentTypes = {
    other: 'Other',
    invoice: 'Invoice',
    billOfLading: 'BillOfLading',
    scaleTicket: 'ScaleTicket',
};

export const UploadAttachmentExtensionTypes = {
    heic: 'heic',
    heif: 'heif',
};
export const DriverLoadAttachmentType = [
    { value: 'BillOfLading', label: 'Bill Of Lading' },
    { value: 'ScaleTicket', label: 'Scale Ticket' },
    { value: 'Other', label: 'Other' },
];
