import { ILanguage } from './interfaces';

export const LocalizationLanguages: ILanguage[] = [
    {
        code: 'en',
        name: 'English',
    },
    {
        code: 'es',
        name: 'Spanish',
    },
];
