import { notifyError } from './axios';

export const API_ERROR_MESSAGE =
    'There was an issue handling your request. Please contact a site administrator.';

const API_ERROR_NAME = 'API Error';
const API_SUCCESS_NAME = 'API Successful';
const API_SUCCESS_MESSAGE = 'API call Successful';

// HTTP Error Codes range from 100-599 https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
// API Error Codes range from 1000-1199
// An API Error code represents an error that is specific to the api being called.
export enum APIErrorCode {
    Ok = 1000,
    Unknown = 1199,
}

export class APIStatus extends Error {
    errorCode?: APIErrorCode;
    httpStatusCode?: number;
    constructor(error: APIStatus) {
        const message = error.message ? error.message : API_ERROR_MESSAGE;
        super(`${error.errorCode}: ${message}`);
        this.errorCode = error.errorCode;
        this.httpStatusCode = error.httpStatusCode;
    }
}

export const APIStatusOK = new APIStatus({
    name: API_SUCCESS_NAME,
    message: API_SUCCESS_MESSAGE,
    errorCode: APIErrorCode.Ok,
});

export const throwAPIError = (errorCode: APIErrorCode): APIStatus => {
    notifyError();

    const apiError = new APIStatus({
        name: API_ERROR_NAME,
        message: API_ERROR_MESSAGE,
        errorCode,
    });

    throw apiError;
};
