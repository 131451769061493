import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IPindrop,
    IShipmentLocation,
    IShipmentRecord,
} from '../../api/shipment/interfaces';
import { deepClone } from '../../utils/functions';
import { ISelect } from '../../utils/interfaces';
import { LocationDetailConstants } from './constants';
import { SHIPMENT_INITIAL_STATE } from './initialState';
import {
    ICommodityModalDetail,
    ICommodityModalErrorDetail,
    IEstimateRateDetail,
    ILocation,
    ILocationModalDetail,
    INewShipmentBasicInfo,
} from './interfaces';

export const shipmentSlice = createSlice({
    name: 'shipment',
    initialState: SHIPMENT_INITIAL_STATE,
    reducers: {
        setActiveTab: (state, action: PayloadAction<string>) => {
            state.activeTab = action.payload;
        },
        setShowShipmentModal: (state, action: PayloadAction<boolean>) => {
            state.showShipmentModal = action.payload;
        },
        setIsShipmentUpdate: (state, action) => {
            state.isShipmentUpdate = action.payload;
        },
        setNewShipmentBasicInfo: (
            state,
            action: PayloadAction<INewShipmentBasicInfo>,
        ) => {
            state.newShipment = action.payload;
        },
        setSelectedPinDropLocation: (
            state,
            action: PayloadAction<ILocation | undefined>,
        ) => {
            state.selectedPinDropLocation = action.payload;
        },
        updateNewShipmentBasicInfo: (
            state,
            action: PayloadAction<Partial<INewShipmentBasicInfo>>,
        ) => {
            state.newShipment = {
                ...state.newShipment,
                ...action.payload,
            };
        },
        setEstimateRateDetail: (
            state,
            action: PayloadAction<IEstimateRateDetail[]>,
        ) => {
            state.estimateRateList = action.payload;
        },
        setShipmentLocations: (
            state,
            action: PayloadAction<IShipmentLocation[]>,
        ) => {
            state.shipmentLocations = action.payload;
        },
        setLocations: (state, action: PayloadAction<ILocation[]>) => {
            state.locations = action.payload;
        },
        setDraftLocations: (state, action: PayloadAction<ILocation[]>) => {
            state.draftLocations = action.payload;
        },
        setLocationDetail: (
            state,
            action: PayloadAction<ILocationModalDetail>,
        ) => {
            state.locationModalDetail = action.payload;
        },
        setLocationModalDetail: (
            state,
            action: PayloadAction<Partial<ILocationModalDetail>>,
        ) => {
            state.locationModalDetail = {
                ...state.locationModalDetail,
                ...action.payload,
            };
        },
        setPindrops: (state, action: PayloadAction<ISelect[]>) => {
            state.pindrops = action.payload;
        },
        setPindropsList: (state, action: PayloadAction<IPindrop[]>) => {
            state.pindropList = action.payload;
        },
        setShowNewCustomerBrokerModal: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showNewCustomerBrokerModal = action.payload;
        },
        setShowEstimateRatesModal: (state, action: PayloadAction<boolean>) => {
            state.showEstimateRatesModal = action.payload;
        },
        resetAddLocationModalStates: state => {
            state.locationModalDetail =
                SHIPMENT_INITIAL_STATE.locationModalDetail;
            state.pindrops = SHIPMENT_INITIAL_STATE.pindrops;
            state.pindropList = SHIPMENT_INITIAL_STATE.pindropList;
        },
        resetLocations: state => {
            const locationDetailCopy = deepClone(state.locations);

            locationDetailCopy[0] = SHIPMENT_INITIAL_STATE.locations[0];
            locationDetailCopy[1] = SHIPMENT_INITIAL_STATE.locations[1];

            for (let i = 2; i < locationDetailCopy.length; i++) {
                locationDetailCopy[i] = LocationDetailConstants.newLocation;
            }

            state.locations = locationDetailCopy;
            state.newShipment.estimatedDistance =
                SHIPMENT_INITIAL_STATE.newShipment.estimatedDistance;
        },
        resetAllShipmentStates: state => {
            return {
                ...SHIPMENT_INITIAL_STATE,
                isSubmitting: false,
                showShipmentModal: state.showShipmentModal,
                commodityList: state.commodityList,
                pindropList: state.pindropList,
                pindrops: state.pindrops,
            };
        },
        setShipmentRecord: (state, action: PayloadAction<IShipmentRecord>) => {
            state.shipmentRecord = action.payload;
        },
        setCanResetTime: (state, action: PayloadAction<boolean>) => {
            state.canResetTime = action.payload;
        },
        setShipmentDetailPayload: (
            state,
            action: PayloadAction<Partial<IShipmentRecord>>,
        ) => {
            state.shipmentRecord = {
                ...state.shipmentRecord,
                ...action.payload,
            };
        },
        setCommodityList: (state, action: PayloadAction<ISelect[]>) => {
            state.commodityList = action.payload;
        },
        setCommodityModalDetail: (
            state,
            action: PayloadAction<ICommodityModalDetail>,
        ) => {
            state.commodityModalDetail = action.payload;
        },
        setCommodityModalErrorDetail: (
            state,
            action: PayloadAction<ICommodityModalErrorDetail>,
        ) => {
            state.commodityModalErrorDetail = action.payload;
        },
        setCommodityDetailPayload: (
            state,
            action: PayloadAction<Partial<ICommodityModalDetail>>,
        ) => {
            state.commodityModalDetail = {
                ...state.commodityModalDetail,
                ...action.payload,
            };
        },
        setCommodityErrorDetailPayload: (
            state,
            action: PayloadAction<Partial<ICommodityModalErrorDetail>>,
        ) => {
            state.commodityModalErrorDetail = {
                ...state.commodityModalErrorDetail,
                ...action.payload,
            };
        },
        setLongestRoute: (
            state,
            action: PayloadAction<google.maps.LatLngLiteral[]>,
        ) => {
            state.longestRoute = action.payload;
        },
        addLocation: state => {
            state.locations = [
                ...state.locations,
                LocationDetailConstants.newLocation,
            ];
        },
        removeLocation: (state, action: PayloadAction<number>) => {
            state.locations = [
                ...state.locations.filter(
                    (_locationDetail, locationIndex) =>
                        locationIndex !== action.payload,
                ),
            ];
        },
        setShowDeleteBuildShipmentModal: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showDeleteShipmentModal = action.payload;
        },
    },
});

export const {
    setActiveTab,
    setShowShipmentModal,
    setNewShipmentBasicInfo,
    updateNewShipmentBasicInfo,
    setShipmentLocations,
    setLocations,
    setDraftLocations,
    setLocationModalDetail,
    setPindrops,
    setPindropsList,
    setLocationDetail,
    resetAddLocationModalStates,
    resetAllShipmentStates,
    resetLocations,
    setEstimateRateDetail,
    setShowNewCustomerBrokerModal,
    setShowEstimateRatesModal,
    setShipmentRecord,
    setShipmentDetailPayload,
    setCanResetTime,
    setCommodityList,
    setCommodityModalDetail,
    setCommodityModalErrorDetail,
    setCommodityDetailPayload,
    setCommodityErrorDetailPayload,
    setLongestRoute,
    addLocation,
    removeLocation,
    setSelectedPinDropLocation,
    setIsShipmentUpdate,
    setShowDeleteBuildShipmentModal,
} = shipmentSlice.actions;

const ShipmentReducer = shipmentSlice.reducer;
export default ShipmentReducer;
