export const FeatureTypes = {
    buildShipment: 'BUILD_SHIPMENT',
    assignDriver: 'ASSIGN_DRIVER',
    dispatchDrivers: 'DISPATCH_DRIVERS',
    assignCommodities: 'ASSIGN_COMMODITIES_TO_DRIVERS',
    updateShipment: 'UPDATE_SHIPMENT',
    verifyRate: 'VERIFY_RATE',
    breakdownDriver: 'BREAKDOWN_DRIVER',
    resolveBreakdown: 'RESOLVE_BREAKDOWN',
    uploadDocuments: 'UPLOAD_DOCUMENTS',
    cancelDriver: 'CANCEL_DRIVER',
    cancelShipment: 'CANCEL_ALL_LOAD_IN_THE_SHIPMENT',
    createInvoice: 'CREATE_INVOICE',
    updateInvoice: 'UPDATE_INVOICE',
    createCustomer: 'CREATE_CUSTOMER',
    createDriver: 'CREATE_DRIVER',
    createBroker: 'CREATE_BROKER',
    updateCustomer: 'UPDATE_CUSTOMER',
    updateDriver: 'UPDATE_DRIVER',
    updateBroker: 'UPDATE_BROKER',
    pinDropsAction: 'CREATE_PINDROPS',
    viewPinDrops: 'VIEW_PIN_DROPS',
    updateSettings: 'UPDATE_SETTINGS',
    enableDriverApp: 'ENABLE_DRIVER_APP_FOR_DRIVER',
    viewUsersList: 'VIEW_USERS',
    createUser: 'ADD_USERS',
    editUser: 'EDIT_USERS',
    manageTrailer: 'MANAGE_TRAILERS',
};
