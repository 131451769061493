import jwtDecode from 'jwt-decode';

interface IUseUserRole {
    isProtectedRoute: (roles: string[]) => boolean;
}

interface ITokenDetails {
    aud: string;
    email: string;
    exp: number;
    iat: number;
    iss: string;
    nbf: number;
    role: string;
    sub: string;
    tid: string;
}

export const useUserRole = (): IUseUserRole => {
    const userToken =
        sessionStorage.getItem('token') || localStorage.getItem('token') || '';

    const decodedToken: ITokenDetails | null = userToken
        ? jwtDecode(userToken)
        : null;

    const isProtectedRoute = (roles: string[]): boolean => {
        return roles.some(allowedRole =>
            decodedToken?.role.includes(allowedRole),
        );
    };

    return {
        isProtectedRoute,
    };
};
