import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const DateRange = createSlice({
    name: 'DateRange',
    initialState: {
        isDateRangeInValid: false,
        isDateInValid: false,
        hideDateErrorMessage: false,
        hideDateRangeErrorMessage: false,
    },
    reducers: {
        setIsDateRangeInValid: (state, action: PayloadAction<boolean>) => {
            state.isDateRangeInValid = action.payload;
        },
        setIsDateInValid: (state, action: PayloadAction<boolean>) => {
            state.isDateInValid = action.payload;
        },
        setHideDateErrorMessage: (state, action: PayloadAction<boolean>) => {
            state.hideDateErrorMessage = action.payload;
        },
        setHideDateRangeErrorMessage: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.hideDateRangeErrorMessage = action.payload;
        },
    },
});

export const {
    setIsDateRangeInValid,
    setIsDateInValid,
    setHideDateErrorMessage,
    setHideDateRangeErrorMessage,
} = DateRange.actions;

const DateRangeReducer = DateRange.reducer;
export default DateRangeReducer;
