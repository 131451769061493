export const getFeatureFlagValue = (
    envVariableValue: string | undefined,
): boolean => {
    if (envVariableValue === 'on') {
        return true;
    } else if (envVariableValue === 'off') {
        return false;
    } else {
        return process.env.REACT_APP_ENVIRONMENT === 'local';
    }
};
