export const PER_MILE = 'PerMile';
export const PER_CWT = 'PerCWT';
export const FLAT = 'Flat';
export const PER_HEADCOUNT = 'PerHeadCount';

export const RateTypeDropdown = [
    { label: 'Select Rate Type', value: 'None' },
    { label: 'Per Mile', value: PER_MILE },
    { label: 'Per CWT', value: PER_CWT },
    { label: 'Flat', value: FLAT },
    { label: 'Per HeadCount', value: PER_HEADCOUNT },
];
