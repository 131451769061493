import { combineReducers } from '@reduxjs/toolkit';
import createAssociate from './associate/reducers/createAssociate';
import uploadAttachment from './attachment/reducers/uploadAttachment';
import countryCode from './countryCode/reducers/countryCode';
import UnsavedChangesReducer from './navigation/UnsavedChangesReducer';
import LocationModalReducer from './LocationModal/reducer';
import BreakdownDriverModalReducer from './BreakdownDriverModal/reducer/BreakdownDetailsReducer';
import SubmittingButtonReducer from './Submitting/SubmittingButtonReducer';
import DateRange from '../components/Form/DateRangeInputField/reducer';

export default combineReducers({
    createAssociate,
    uploadAttachment,
    countryCode,
    UnsavedChangesReducer,
    LocationModalReducer,
    BreakdownDriverModalReducer,
    SubmittingButtonReducer,
    DateRange,
});
