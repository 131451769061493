import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import associateApi from '../../../api/associate/associateApi';
import { IDriverCard } from '../../../api/driver/interfaces';
import { DriverUtils } from '../../../utils/DriverUtils';
import { IDriverListInitialState } from '../interfaces';

export const fetchDriverList = createAsyncThunk(
    'fetchDriverList',
    async (
        {
            currentLocation,
            driverStatus,
            driverName,
        }: {
            currentLocation?: string;
            driverStatus?: boolean;
            driverName?: string;
        },
        { rejectWithValue },
    ) => {
        const driverUtils = new DriverUtils();

        try {
            const response = await associateApi.fetchDriverList(
                currentLocation,
                driverStatus,
                driverName,
            );
            return driverUtils.formatDriverList(response.data);
        } catch {
            return rejectWithValue('Error');
        }
    },
);

const initialState: IDriverListInitialState = {
    driverListLoading: false,
    driverList: [],
    showTruckingCompanyModal: false,
};

export const driverListSlice = createSlice({
    name: 'fetchDriverList',
    initialState,
    reducers: {
        setDriverList: (state, action: PayloadAction<IDriverCard[]>) => {
            state.driverList = action.payload;
        },
        setShowTruckingCompanyModal: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showTruckingCompanyModal = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchDriverList.pending, state => {
            state.driverListLoading = true;
            state.driverList = [];
        });
        builder.addCase(fetchDriverList.fulfilled, (state, action) => {
            state.driverListLoading = false;
            state.driverList = action.payload;
        });
        builder.addCase(fetchDriverList.rejected, state => {
            state.driverListLoading = false;
        });
    },
});

export const { setDriverList, setShowTruckingCompanyModal } =
    driverListSlice.actions;

const DriverListReducer = driverListSlice.reducer;
export default DriverListReducer;
