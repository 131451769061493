import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { settingsApi } from '../../../../../api/settings/settingsApi';
import { ISingleValueSelect } from '../../../../../utils/interfaces';
import { IUser } from '../interfaces/IUser';
import { dispatchManagerFeatures } from '../../../../../featureFlags/dispatchManagerFeatures';

export const fetchUserList = createAsyncThunk(
    'fetchUserList',
    async (
        {
            tenantId,
        }: {
            tenantId: string;
        },
        { rejectWithValue },
    ) => {
        const { isHideIntimetecMailIdUsers } = dispatchManagerFeatures;
        const emailFilter = isHideIntimetecMailIdUsers
            ? `and not endswith(Username, 'intimetec.com')`
            : '';
        try {
            const response = await settingsApi.fetchUsersList(
                tenantId,
                emailFilter,
            );
            return response;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export const SELECTED_INITIAL_STATE: IUser = {
    firstName: '',
    lastName: '',
    contactPhone: '',
    username: '',
    roles: [
        {
            roleId: 0,
            name: 'Admin',
        },
    ],
    commission: null,
    enabled: true,
    verified: false,
};

export type IUserInitialState = {
    usersListLoading: boolean;
    usersList: IUser[];
    selectedUser: IUser;
    selectedUserRole: ISingleValueSelect;
    selectedUserStatus: ISingleValueSelect;
};

const initialUserState: IUserInitialState = {
    usersListLoading: false,
    usersList: [],
    selectedUser: SELECTED_INITIAL_STATE,
    selectedUserRole: {
        value: '',
        label: 'Select Role',
    },
    selectedUserStatus: {
        value: '',
        label: '',
    },
};

export const userSlice = createSlice({
    name: 'userDetails',
    initialState: initialUserState,
    reducers: {
        setUserList: (state, action: PayloadAction<IUser[]>) => {
            state.usersList = action.payload;
        },
        setSelectedUser: (state, action: PayloadAction<IUser>) => {
            state.selectedUser = action.payload;
        },
        setSelectedUserRole: (
            state,
            action: PayloadAction<ISingleValueSelect>,
        ) => {
            state.selectedUserRole = action.payload;
        },
        setSelectedUserStatus: (
            state,
            action: PayloadAction<ISingleValueSelect>,
        ) => {
            state.selectedUserStatus = action.payload;
        },
        clearSelectedUser: state => {
            state.selectedUser = SELECTED_INITIAL_STATE;
            state.selectedUserRole = {
                value: '',
                label: 'Select Role',
            };
            state.selectedUserStatus = {
                value: '',
                label: '',
            };
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUserList.pending, state => {
            state.usersListLoading = true;
            state.usersList = [];
        });
        builder.addCase(fetchUserList.fulfilled, (state, action) => {
            state.usersListLoading = false;
            state.usersList = action.payload;
        });
        builder.addCase(fetchUserList.rejected, state => {
            state.usersListLoading = false;
            state.usersList = [SELECTED_INITIAL_STATE];
        });
    },
});

export const {
    setUserList,
    setSelectedUser,
    clearSelectedUser,
    setSelectedUserRole,
    setSelectedUserStatus,
} = userSlice.actions;

const UserReducer = userSlice.reducer;
export default UserReducer;
