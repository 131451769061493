import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { NavigateFunction } from 'react-router-dom';
import { APIStatus, APIStatusOK } from '../../../../api/apiError';
import { notifySuccess } from '../../../../api/axios';
import { driverApi } from '../../../../api/driver/driverApi';
import {
    IDriverDetail,
    IDriverDetailPayload,
} from '../../../../api/driver/interfaces';
import { Routes } from '../../../../utils/routes';
import { DRIVER_DETAIL_INITIAL_STATE } from '../initialState';
import { setIsSubmitting } from '../../../../common/Submitting/SubmittingButtonReducer';
import { HttpStatusCode } from 'axios';
import { AssociateFormErrorState } from '../../../../common/associate/interfaces';

export const updateDriverDetails = createAsyncThunk(
    'updateDriverDetails',
    async (
        {
            payload,
            associateId,
            navigate,
            isNavigate,
            setFormErrorState,
        }: {
            payload: IDriverDetailPayload;
            associateId: string | number;
            navigate?: NavigateFunction;
            isNavigate: boolean;
            setFormErrorState: (
                key: keyof AssociateFormErrorState,
                value: boolean,
            ) => void;
        },
        { rejectWithValue, dispatch },
    ) => {
        try {
            const response = await driverApi.updateDriverDetail(
                associateId,
                payload,
            );
            if (
                response &&
                response >= HttpStatusCode.Ok &&
                response < HttpStatusCode.MultipleChoices &&
                isNavigate
            ) {
                navigate && navigate(Routes.drivers);
                notifySuccess(t('DriverDetails:updatedDriverDetails'));
            } else if (
                Number(response) === HttpStatusCode.UnprocessableEntity
            ) {
                setFormErrorState('email', true);
            }
        } catch {
            return rejectWithValue('Error');
        } finally {
            dispatch(setIsSubmitting(false));
        }
    },
);

export interface IDriverDetailInitialState {
    loading: boolean;
    updateDriverDetails: IDriverDetail;
    status: APIStatus;
}

const initialState: IDriverDetailInitialState = {
    loading: false,
    updateDriverDetails: DRIVER_DETAIL_INITIAL_STATE.updatedDriverDetail,
    status: APIStatusOK,
};

export const updateDriverDetailSlice = createSlice({
    name: 'updateDriverDetails',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateDriverDetails.pending, state => {
            state.loading = true;
        });
        builder.addCase(updateDriverDetails.fulfilled, state => {
            state.loading = false;
        });
        builder.addCase(updateDriverDetails.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

const UpdateDriverDetailsReducer = updateDriverDetailSlice.reducer;
export default UpdateDriverDetailsReducer;
