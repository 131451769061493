export const AssignDriverCargoDetailTitles = [
    'Commodity',
    'Miles',
    'Weight(lbs)',
    'Head Count',
    'Rate Type',
    'Rate',
    'Prorated',
    'Prorate Wt(lbs)',
    'Prorate Rate',
    'Total Rate',
];

export const FilterType = {
    driverList: 'DriverList',
    statusSearch: 'StatusSearch',
};
