import { ICargoDetails } from './interfaces/ICargoDetails';
import { ICargoDetailsInitialState } from './interfaces/ICargoDetailsInitialState';

export const CARGO_ITEM: ICargoDetails = {
    selectedCommodity: {
        value: '',
        label: '',
    },
    cargoPickups: [],
    cargoDropoffs: [],
    shipmentCargoLocations: [],
    driverLoadCargoLocations: [],
    rateType: {
        value: '',
        label: '',
    },
    rateInUSD: '',
    estMiles: null,
    estHeadCount: null,
    estWeightInPounds: null,
    actualMiles: null,
    breakdownMiles: null,
    actualHeadCount: null,
    actualWeightInPounds: null,
    deads: null,
    cripples: null,
    producer: null,
    prorateWeightInPounds: '',
    prorateRate: '',
    isProrated: false,
    estTotalRateInUSD: '',
    actualTotalRateInUSD: '',
    isCargoAccordionActive: false,
    isAddAllLocationToCommodity: false,
};

export const CARGO_DETAILS_INITIAL_STATE: ICargoDetailsInitialState = {
    currentActiveCargoLocation: {
        cargoIndex: null,
        name: null,
    },
    cargos: [CARGO_ITEM],
    createdCommodityDetails: {
        id: null,
        index: null,
    },
    estimatedTotalRate: '',
    actualTotalRate: '',
    showCommodityModal: false,
    showReverifyRateModal: false,
    reVerifyCommodityTypes: [],
    draftShipmentCargos: [],
    isApplyRateToAllChecked: false,
};
