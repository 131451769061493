import {
    BuildShipmentTabConstants,
    LocationDetailConstants,
} from './constants';
import { IShipmentInitialState } from './interfaces';

export const SHIPMENT_INITIAL_STATE: IShipmentInitialState = {
    isShipmentUpdate: false,
    activeTab: BuildShipmentTabConstants.customerDetail,
    showShipmentModal: false,
    newShipment: {
        customerName: '',
        contractNumber: '',
        estimatedDistance: '',
    },
    showNewCustomerBrokerModal: false,
    showEstimateRatesModal: false,
    shipmentLocations: [],
    locations: [
        {
            locationId: 0,
            locationType: LocationDetailConstants.locationTypes.pickUp,
            streetAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            latitude: 0,
            longitude: 0,
            instructions: '',
            completeAddress: '',
            pindrop: {
                name: '',
                pinDropId: '',
            },
        },
        {
            locationId: 0,
            locationType: LocationDetailConstants.locationTypes.dropOff,
            streetAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            latitude: 0,
            longitude: 0,
            instructions: '',
            completeAddress: '',
            pindrop: {
                name: '',
                pinDropId: '',
            },
        },
    ],
    draftLocations: [],
    locationModalDetail: {
        pinDropId: undefined,
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        latitude: null,
        longitude: null,
        pinDropName: '',
        instructions: '',
    },
    pindrops: [],
    pindropList: [],
    coordinates: { lat: '', lng: '' },
    estimateRateList: [],
    shipmentRecord: {
        loadNumber: null,
        loadTime: '',
        timezone: '',
        deliveryEndDate: '',
        deliveryStartDate: '',
        _49kTrucksRequired: null,
        _52kTrucksRequired: null,
        _55_62kTrucksRequired: null,
        orderNotes: '',
        driverInstructions: '',
        isCattlePORequest: false,
    },
    canResetTime: false,
    commodityList: [],
    commodityModalDetail: {
        commodityName: '',
    },
    commodityModalErrorDetail: {
        commodityName: false,
    },
    longestRoute: [],
    selectedPinDropLocation: {
        locationId: 0,
        locationType: LocationDetailConstants.locationTypes.pickUp,
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        latitude: 0,
        longitude: 0,
        instructions: '',
        completeAddress: '',
        pindrop: {
            name: '',
            pinDropId: '',
        },
    },
    showDeleteShipmentModal: false,
};
