import { IAuthInitialState } from './interface';

export const AUTH_INITIAL_STATE: IAuthInitialState = {
    signInData: {
        refreshToken: '',
        refreshTokenExpiry: '',
        roles: [],
        tenantId: '',
        token: '',
        actionPermissions: '',
    },
    userEmail: '',
    tenantId: '',
    userRole: '',
    userToken: '',
    permissionList: [],
};
