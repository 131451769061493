import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { dashboardApi } from '../../../api/dashboard/dashboardApi';
import { IShipment } from '../../../api/shipment/interfaces';

export const fetchUpcomingShipments = createAsyncThunk(
    'fetchUpcomingShipments',
    async (_, { rejectWithValue }) => {
        try {
            return await dashboardApi.fetchUpcomingShipments();
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export interface IUpcomingShipmentsInitialState {
    loading: boolean;
    upcomingShipments: IShipment[];
    status: APIStatus;
}

const initialState: IUpcomingShipmentsInitialState = {
    loading: false,
    upcomingShipments: [],
    status: APIStatusOK,
};

export const UpcomingShipmentsSlice = createSlice({
    name: 'fetchUpcomingShipments',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchUpcomingShipments.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchUpcomingShipments.fulfilled, (state, action) => {
            state.loading = false;
            state.upcomingShipments = action.payload;
        });
        builder.addCase(fetchUpcomingShipments.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

const UpcomingShipmentsReducer = UpcomingShipmentsSlice.reducer;
export default UpcomingShipmentsReducer;
