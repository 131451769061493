import { FC } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RollOnDispatchIcon from '../../assets/icons/RollOnDispatchIcon';
import { useUserRole } from '../../common/hooks/useUserRole';
import LocaleDropdown from '../../components/LocaleDropdown';
import { useAppSelector } from '../../redux/hooks';
import { useIsWebView } from '../../utils/hooks/useIsWebView';
import { Support } from '../Support';
import SettingsSelect from './components/SettingsSelect';
import UserProfile from './components/UserProfile';
import { HeaderConstants } from './constants';
import './style.css';
import { useNavbar } from './useNavbar';
import { dispatchManagerFeatures } from '../../featureFlags/dispatchManagerFeatures';
interface IHeader {
    roles: string[];
    eventKey: string;
    keyName: string;
}

const Header: FC = () => {
    const { t } = useTranslation();
    const navHeaders = Object.values(HeaderConstants);
    const { handleNavigationSelect, getHeaderName, expanded, setExpanded } =
        useNavbar();
    const isWebView = useIsWebView();
    const { keySelected } = useAppSelector(state => state.navbar);
    const { isProtectedRoute } = useUserRole();
    const { isShowActivityLog } = dispatchManagerFeatures;

    return (
        <Navbar
            className="navbar"
            variant="dark"
            expanded={expanded}
            onToggle={() => setExpanded(!expanded)}
        >
            <Navbar.Brand>
                <RollOnDispatchIcon width="167" height="47.85" />
            </Navbar.Brand>
            {!isWebView && (
                <Navbar.Text>
                    {navHeaders.map((headerDetail: IHeader) =>
                        keySelected === headerDetail.eventKey ? (
                            <span key={headerDetail.eventKey}>
                                {t(headerDetail.keyName)}
                            </span>
                        ) : null,
                    )}
                </Navbar.Text>
            )}
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                    className="me-auto"
                    defaultActiveKey={HeaderConstants.dashboard.eventKey}
                    onSelect={handleNavigationSelect}
                >
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <div className="nav-inside">
                        {navHeaders
                            .filter(
                                headerDetail =>
                                    !(
                                        !isShowActivityLog &&
                                        headerDetail.eventKey ===
                                            HeaderConstants.activityLog.eventKey
                                    ) &&
                                    !(
                                        t(
                                            getHeaderName(headerDetail.keyName),
                                        ) === ''
                                    ),
                            )
                            .map((headerDetail: IHeader, index: number) =>
                                t(headerDetail.keyName) ===
                                t(HeaderConstants.userProfile.keyName) ? (
                                    <UserProfile
                                        key={headerDetail.eventKey}
                                        isMobileView={true}
                                    />
                                ) : (
                                    <Nav.Link
                                        key={headerDetail.eventKey}
                                        eventKey={headerDetail.eventKey}
                                        className={
                                            keySelected ===
                                            headerDetail.eventKey
                                                ? 'navbar-option-selected'
                                                : 'navbar-option'
                                        }
                                    >
                                        <span>
                                            {isProtectedRoute(
                                                headerDetail.roles,
                                            ) && t(headerDetail.keyName)}
                                        </span>
                                    </Nav.Link>
                                ),
                            )}
                    </div>
                </Nav>
            </Navbar.Collapse>

            <div className="d-flex align-items-center">
                {isWebView && (
                    <div className="d-none d-md-flex d-lg-flex align-items-between justify-content-between">
                        <Support />
                        <LocaleDropdown />
                        <SettingsSelect />
                        <UserProfile />
                    </div>
                )}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
        </Navbar>
    );
};

export default Header;
