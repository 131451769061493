import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { dashboardApi } from '../../../api/dashboard/dashboardApi';
import { IShipment } from '../../../api/shipment/interfaces';

export const fetchReadyForBillingShipments = createAsyncThunk(
    'fetchReadyForBillingShipments',
    async (_, { rejectWithValue }) => {
        try {
            return await dashboardApi.fetchReadyForBillingShipments();
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export interface IReadyForBillingShipmentsInitialState {
    loading: boolean;
    readyForBillingShipments: IShipment[];
    status: APIStatus;
}

const initialState: IReadyForBillingShipmentsInitialState = {
    loading: false,
    readyForBillingShipments: [],
    status: APIStatusOK,
};

export const ReadyForBillingShipmentsSlice = createSlice({
    name: 'fetchReadyForBillingShipments',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchReadyForBillingShipments.pending, state => {
            state.loading = true;
        });
        builder.addCase(
            fetchReadyForBillingShipments.fulfilled,
            (state, action) => {
                state.loading = false;
                state.readyForBillingShipments = action.payload;
            },
        );
        builder.addCase(
            fetchReadyForBillingShipments.rejected,
            (state, action) => {
                state.loading = false;
                state.status = action.payload as APIStatus;
            },
        );
    },
});

const ReadyForBillingShipmentsReducer = ReadyForBillingShipmentsSlice.reducer;
export default ReadyForBillingShipmentsReducer;
