import { PayloadAction, createSlice, nanoid } from '@reduxjs/toolkit';
import { MAPS_INITIAL_STATE } from './initialState';
import { IMapDetails } from './interface';

export const mapsSlice = createSlice({
    name: 'maps',
    initialState: MAPS_INITIAL_STATE,
    reducers: {
        setMapDetails: (state, action: PayloadAction<IMapDetails>) => {
            state.mapDetails = action.payload;
        },
        setMapDetail: (
            state,
            action: PayloadAction<{
                coordinates: {
                    lat: number | string;
                    lng: number | string;
                };
                zoom: number;
            }>,
        ) => {
            const { coordinates, zoom } = action.payload;
            const updatedLocation = {
                lat: coordinates.lat ? +coordinates.lat : 0,
                lng: coordinates.lng ? +coordinates.lng : 0,
            };

            state.mapDetails.zoom = zoom;
            state.mapDetails.center = updatedLocation;
            state.mapDetails.location = updatedLocation;
            state.mapDetails.markers = [
                {
                    position: updatedLocation,
                    key: nanoid(),
                },
            ];
        },
    },
});

export const { setMapDetails, setMapDetail } = mapsSlice.actions;

const MapsReducer = mapsSlice.reducer;
export default MapsReducer;
