import { t } from 'i18next';
import { notifySuccess } from '../../api/axios';
import { IShipmentLoadBoardViewItem } from '../../api/shipment/interfaces';
import { shipmentsApi } from '../../api/shipment/shipmentsApi';
import { RangeValue } from '../../components/Form/DateRangeInputField/DateRangeInputField';
import i18n from '../../i18n/i18nInit';
import { API_CONSTANTS } from '../../services/apiConstants';
import { ApplyFiltersFormField } from './components/LoadBoardFilters/SearchFilters/ApplyFiltersForm.config';
import {
    searchDateFilterDetailsMap,
    searchFilterDetailsMap,
} from './components/LoadBoardList/utils/FilterUtil';
import { ILoadBoardSearchFilters, ILoadBoardStatusFilters } from './interfaces';
import { ISelectedDriverIdList } from './interfaces/ISelectedDriverList';
import { setIsSubmitting } from '../../common/Submitting/SubmittingButtonReducer';
import { AppDispatch } from '../../redux/store';

export const buildFetchShipmentDetailsApiUrl = (
    loadBoardSearchFilters: ILoadBoardSearchFilters,
    loadBoardBadgesFilters: ILoadBoardStatusFilters,
): string => {
    const { driverName, customerName, orderDate, orderNumber, contractNumber } =
        loadBoardSearchFilters;
    const { limit, offset } = loadBoardBadgesFilters;

    let apiUrl = `${API_CONSTANTS.shipments}/odata?orderby=ShipmentStatus/SortOrder,deliveryStartDate asc&include=ShipmentLocations,ShipmentLocations.Location,ShipmentLocations.Location.TagGroup,Associate,ShipmentStatus,DriverLoads,DriverLoads.Associate,DriverLoads.Associate.Contacts,DriverLoads.Attachments,ShipmentCargoDetails.Commodity,ShipmentCargoDetails,ShipmentCargoDetails.ShipmentCargoLocations,DriverLoads.DriverLoadCargoDetails,DriverLoads.DriverLoadCargoDetails.Commodity,DriverLoads.DriverLoadCargoDetails.DriverLoadCargoLocations`;

    const statusFilters: string = buildStatusFilters(loadBoardBadgesFilters);
    let filterString = '';

    if (
        driverName === t('LB_Common:selectDriverName') &&
        customerName === t('LB_Common:selectCustomerName')
    ) {
        filterString += buildFilterString({
            driverName: '',
            customerName: '',
            orderDate,
            orderNumber,
            contractNumber,
        });
    } else if (driverName === t('LB_Common:selectDriverName')) {
        filterString += buildFilterString({
            driverName: '',
            customerName,
            orderDate,
            orderNumber,
            contractNumber,
        });
    } else if (customerName === t('LB_Common:selectCustomerName')) {
        filterString += buildFilterString({
            driverName,
            customerName: '',
            orderDate,
            orderNumber,
            contractNumber,
        });
    } else {
        filterString = buildFilterString(loadBoardSearchFilters);
    }

    if (filterString.length > 0 || statusFilters.length > 0) {
        apiUrl += `&$filter=${filterString}${
            filterString.length > 0 && statusFilters.length > 0
                ? ` and ${statusFilters}`
                : statusFilters.length > 0
                ? statusFilters
                : ''
        }`;
    }

    if (limit) {
        apiUrl += `&$top=${offset + limit}`;
    }

    return apiUrl;
};

const buildStatusFilters = (
    loadBoardBadgesFilters: ILoadBoardStatusFilters,
): string => {
    if (loadBoardBadgesFilters.selectedBadges.length === 0) {
        return '';
    }

    return `(${loadBoardBadgesFilters.selectedBadges
        .map(badge => `(ShipmentStatus/Name eq '${badge}')`)
        .join(' or ')})`;
};

const buildFilterString = (
    loadBoardSearchFilters: ILoadBoardSearchFilters,
): string => {
    const filterStrings: (string | undefined)[] = Object.entries(
        loadBoardSearchFilters,
    )
        .filter(([_, value]) => value !== '' && value !== null)
        .map(([key, value]) => {
            if (key === ApplyFiltersFormField.orderDate.name) {
                const filter = searchDateFilterDetailsMap.get(key);
                return filter?.getFilter(value as RangeValue);
            } else {
                const filter = searchFilterDetailsMap.get(key);
                return filter?.getFilter(value as string);
            }
        });

    if (filterStrings.length <= 0) {
        return '';
    }

    return filterStrings.join(' and ');
};

export const updateShipmentService =
    (payload: IShipmentLoadBoardViewItem, shipmentId: number) =>
    async (dispatch: AppDispatch) => {
        try {
            await shipmentsApi.updateShipment(shipmentId, payload);
            notifySuccess(t('BS_CommonConstants:shipmentSaveMessage'));
        } finally {
            dispatch(setIsSubmitting(false));
        }
    };

export const updateShipmentStatus = async (
    shipmentId: number,
    selectedDriverIdList: ISelectedDriverIdList[],
    message: string,
): Promise<void> => {
    await shipmentsApi.updateShipmentStatus(shipmentId, selectedDriverIdList);
    notifySuccess(i18n.t(message));
};
