import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import associateApi from '../../../api/associate/associateApi';
import { IDriver } from '../../../api/driver/interfaces';

export const fetchRescueDrivers = createAsyncThunk(
    'fetchRescueDrivers',
    async ({ startDate }: { startDate: string }, { rejectWithValue }) => {
        try {
            const response = await associateApi.fetchRescueDrivers(startDate);
            return response;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export type IRescueDriverInitialState = {
    loading: boolean;
    rescueDriverList: IDriver[];
};

const initialState: IRescueDriverInitialState = {
    loading: false,
    rescueDriverList: [],
};

export const rescueDriversSlice = createSlice({
    name: 'fetchRescueDrivers',
    initialState,
    reducers: {
        setRescueDriverList: (state, action: PayloadAction<IDriver[]>) => {
            state.rescueDriverList = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchRescueDrivers.pending, state => {
            state.loading = true;
            state.rescueDriverList = [];
        });
        builder.addCase(fetchRescueDrivers.fulfilled, (state, action) => {
            state.loading = false;
            state.rescueDriverList = action.payload;
        });
        builder.addCase(fetchRescueDrivers.rejected, state => {
            state.loading = false;
        });
    },
});

export const { setRescueDriverList } = rescueDriversSlice.actions;

const RescueDriversReducer = rescueDriversSlice.reducer;
export default RescueDriversReducer;
