import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { useAppDispatch } from '../../redux/hooks';
import { setCountryCodeOptions } from './reducers/countryCode';
import { CountryCode, CountryCallingCode } from 'libphonenumber-js/types';
import { ISelect } from '../../utils/interfaces';

interface ICoutryCode {
    loadCountryCodeOptions: () => void;
}

export const useCountryCode = (): ICoutryCode => {
    const dispatch = useAppDispatch();

    const getUniqueCountryCallingCodes = (): CountryCallingCode[] => {
        const uniqueCountryCallingCodes: Set<CountryCallingCode> = new Set();

        getCountries().forEach((countryCode: CountryCode) => {
            const callingCode = getCountryCallingCode(countryCode);
            if (callingCode) {
                uniqueCountryCallingCodes.add(callingCode);
            }
        });

        return Array.from(uniqueCountryCallingCodes);
    };

    const loadCountryCodeOptions = (): void => {
        const uniqueCountryCallingCodes: CountryCallingCode[] =
            getUniqueCountryCallingCodes();

        const countryCodeOptions: ISelect[] = uniqueCountryCallingCodes.map(
            (countryCode: string, index: number) => ({
                label: `+${countryCode}`,
                value: index.toString(),
            }),
        );

        dispatch(setCountryCodeOptions(countryCodeOptions));
    };

    return {
        loadCountryCodeOptions,
    };
};
