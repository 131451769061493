export const ApplyFiltersFormField = {
    orderDate: {
        name: 'orderDate',
        label: 'LB_Common:orderDate',
    },
    order: {
        name: 'orderNumber',
        label: 'LB_Common:orderNumber',
        placeholder: 'LB_Common:enterOrder',
    },
    contractNumber: {
        name: 'contractNumber',
        label: 'LB_Common:contractNumber',
        placeholder: 'LB_Common:enterContractNumber',
    },
};
