import { dateFormatter } from '../../../utils/functions';
import { AssociateType } from '../../Associate/enum';
import {
    BrokerShipmentHistoryTitles,
    CustomerShipmentHistoryTitles,
    DriverShipmentHistoryTitles,
} from '../constants';

export interface IShipmentHistoryUtils {
    shipmentHistoryFilterQuery: (
        associateId: string | number,
        associateType: AssociateType,
        deliveryStartDate?: string,
        deliveryEndDate?: string,
    ) => string;
    shipmentHistoryTitles: (associateType: AssociateType) => string[];
}

export class ShipmentHistoryUtils implements IShipmentHistoryUtils {
    shipmentHistoryFilterQuery = (
        associateId: string | number,
        associateType: AssociateType,
        deliveryStartDate?: string | Date,
        deliveryEndDate?: string | Date,
    ): string => {
        let query = '$orderby=CreatedDatetime desc';
        let includeQuery = `&include=Associate,ShipmentLocations.Location,ShipmentCargoDetails.Commodity,ShipmentStatus,Associate`;
        if (associateType === AssociateType.Driver) {
            includeQuery += ',DriverLoads.DriverLoadCargoDetails.Commodity';
            query += `&$filter=(DriverLoads/any(d: d/AssociateId eq ${associateId} and d/DeliveredDateTime ne null))`;
        } else if (associateType === AssociateType.Customer) {
            query =
                query +
                `&$filter=((AssociateId eq ${associateId}) and (ShipmentStatus/Name ne 'ContinueBuilding'))`;
        } else if (associateType === AssociateType.Broker) {
            query =
                query +
                `&$filter=((AssociateId eq ${associateId}) or (DriverLoads/any(d: d/AssociateId eq ${associateId})))`;
        }

        if (deliveryStartDate) {
            query += `and (DeliveryStartDate ge ${dateFormatter(
                deliveryStartDate,
            )})`;
        }
        if (deliveryEndDate) {
            query += `and (DeliveryStartDate le ${dateFormatter(
                deliveryEndDate,
            )})`;
        }

        return query + includeQuery;
    };

    shipmentHistoryTitles = (associateType: AssociateType): string[] => {
        if (associateType === AssociateType.Broker) {
            return BrokerShipmentHistoryTitles;
        } else if (associateType === AssociateType.Customer) {
            return CustomerShipmentHistoryTitles;
        } else {
            return DriverShipmentHistoryTitles;
        }
    };
}
