import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import associateApi from '../../../api/associate/associateApi';
import { IAssociate } from '../../../api/associate/interfaces';
import { ICustomerListInitialState } from '../interfaces/ICustomerListInitialState';

export const fetchCustomerList = createAsyncThunk(
    'fetchCustomerList',
    async (
        {
            limit,
            offset,
            handleCanCallApi,
            customerName,
            primaryContact,
            isShowBrokersOnlySelected,
        }: {
            limit?: number;
            offset?: number;
            handleCanCallApi?: (response: IAssociate[]) => void;
            customerName?: string;
            primaryContact?: string;
            isShowBrokersOnlySelected?: boolean;
        },
        { rejectWithValue },
    ) => {
        try {
            const response = await associateApi.fetchCustomerList(
                limit,
                offset,
                customerName,
                primaryContact,
                isShowBrokersOnlySelected,
            );
            handleCanCallApi && handleCanCallApi(response.data);
            return response.data;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

const initialState: ICustomerListInitialState = {
    loading: false,
    customerList: [],
};

export const customerListSlice = createSlice({
    name: 'fetchCustomerList',
    initialState,
    reducers: {
        setCustomerList: (state, action: PayloadAction<IAssociate[]>) => {
            state.customerList = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCustomerList.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchCustomerList.fulfilled, (state, action) => {
            state.loading = false;
            state.customerList =
                (action.meta.arg.offset ? action.meta.arg.offset : 0) > 0
                    ? [...state.customerList, ...action.payload]
                    : action.payload;
        });

        builder.addCase(fetchCustomerList.rejected, state => {
            state.loading = false;
        });
    },
});

export const { setCustomerList } = customerListSlice.actions;

const CustomerListReducer = customerListSlice.reducer;
export default CustomerListReducer;
