import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const navbar = createSlice({
    name: 'navbar',
    initialState: { keySelected: '' },
    reducers: {
        setKeySelected: (state, action: PayloadAction<string>) => {
            state.keySelected = action.payload;
        },
    },
});

export const { setKeySelected } = navbar.actions;

const NavbarReducer = navbar.reducer;
export default NavbarReducer;
