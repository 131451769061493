export enum AssociateType {
    Customer = 'Customer',
    Broker = 'Broker',
    Driver = 'Driver',
}

export enum InvoiceType {
    lumpSum = 'LumpSum',
    brokenOut = 'BrokenOut',
}

export enum PriorityType {
    highPriority = 'High',
    normalPriority = 'Normal',
}
