import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import './style.css';
import { t } from 'i18next';

export const AppLoader: FC<{
    loadingPosition: 'flex-start' | 'center' | 'start';
    width?: string;
    height?: string;
}> = ({ loadingPosition, width, height }) => {
    return (
        <div
            className="app-loading-container"
            style={{
                alignItems: loadingPosition,
                width: width,
                height: height,
            }}
        >
            <div className="app-loading">
                <FontAwesomeIcon
                    icon={faTruck}
                    size="lg"
                    spin
                    className="truck-icon"
                />
                {t('Common:loading')}
            </div>
        </div>
    );
};
