export const DocumentationHeader = [
    {
        name: 'FullActivityLog:driverName',
    },
    {
        name: 'FullActivityLog:documentType',
    },
    {
        name: 'FullActivityLog:status',
    },
    {
        name: 'FullActivityLog:statusChangeDate',
    },
    {
        name: '',
    },
];

export const documentationDummyData = [
    {
        documentType: 'Bill of Lading',
        status: 'Approved',
        statusChangeDate: '2024-11-18 03:45 PM',
        driverName: 'John Doe',
    },
    {
        documentType: 'Proof of Delivery',
        status: 'Pending',
        statusChangeDate: '2024-11-19 01:15 PM',
        driverName: 'Jane Smith',
    },
    {
        documentType: 'Inspection Report',
        status: 'Rejected',
        statusChangeDate: '2024-11-20 09:30 AM',
        driverName: 'Carlos Martinez',
    },
    {
        documentType: 'Expense Receipt',
        status: 'Approved',
        statusChangeDate: '2024-11-17 11:50 AM',
        driverName: 'Aisha Khan',
    },
    {
        documentType: 'Maintenance Record',
        status: 'Under Review',
        statusChangeDate: '2024-11-19 04:20 PM',
        driverName: 'Liam Brown',
    },
];
