import { ICustomerInitalState } from './interface';

export const CUSTOMER_INITIAL_STATE: ICustomerInitalState = {
    customersDetail: [],
    customerErrorDetail: {
        customerName: false,
        completeAddress: false,
    },
    selectedCustomer: {
        value: '',
        label: '',
    },
    customers: [],
};
