import { IAttachment } from '../../api/interfaces';
import { CONTACT_INITIAL_STATE } from '../../common/associate/initialState';
import { AssociateTypes } from '../../utils/constants';
import { INewDriverInitialState } from './interfaces';

export const NEW_DRIVER_INITIAL_STATE: INewDriverInitialState = {
    newDriver: {
        associateType: AssociateTypes.driver,
        name: '',
        phoneNumber: '',
        email: null,
        notes: '',
        address: {
            locationId: 0,
            type: '',
            streetAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            latitude: 0,
            longitude: 0,
        },
        isActive: true,
        trailerNumber: null,
        driverTrailers: [],
        truckingCoNumber: '',
        truckingCompanyId: '',
        ownerPhoneNumber: '',
        trailerSize: '',
        unitNumber: null,
        removalReason: '',
        attachments: [],
        profileImage: [],
        tagGroups: [],
        unavailabilities: [{ startDate: null, endDate: null }],
        contacts: [CONTACT_INITIAL_STATE],
        isMobileAppEnabled: false,
        driverType: 'EmployeeDriver',
        ownerOperatorPercent: '',
    },
    truckingCompanyModalDetails: {
        name: '',
        truckingCompanyNumber: null,
    },
    truckingCompanyModalErrorDetails: {
        name: false,
    },
};

export const ATTACHMENT_INITIAL_STATE: IAttachment = {
    Id: 0,
    Attachment: '',
    Expiry: '',
    Type: '',
};
