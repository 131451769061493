import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const submittingButton = createSlice({
    name: 'submittingButton',
    initialState: { isSubmitting: false },
    reducers: {
        setIsSubmitting: (state, action: PayloadAction<boolean>) => {
            state.isSubmitting = action.payload;
        },
    },
});

export const { setIsSubmitting } = submittingButton.actions;

const SubmittingButtonReducer = submittingButton.reducer;
export default SubmittingButtonReducer;
