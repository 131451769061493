import { SelectCallback } from '@restart/ui/esm/types';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { t } from 'i18next';
import {
    setShowWarningModal,
    setUnsavedChanges,
} from '../../common/navigation/UnsavedChangesReducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useIsWebView } from '../../utils/hooks/useIsWebView';
import { Routes } from '../../utils/routes';
import { ConnectedRoutes } from '../../utils/routes/constants';
import { resetLoadBoardShipmentDetails } from '../LoadBoard/reducer';
import { HeaderConstants } from './constants';
import { setKeySelected } from './reducer';

interface IUseNavbar {
    handleConfirmationModal: (confirmToNavigate: boolean) => void;
    handleNavigationSelect: SelectCallback | undefined;
    getHeaderName: (headerName: string) => string;
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useNavbar = (): IUseNavbar => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { hasUnsavedChanges, showWarningModal } = useAppSelector(
        state => state.common.UnsavedChangesReducer,
    );
    const { keySelected } = useAppSelector(state => state.navbar);
    const isWebView = useIsWebView();
    const [expanded, setExpanded] = useState(false);

    const handleConfirmationModal = (confirmToNavigate: boolean): void => {
        if (confirmToNavigate) {
            handleNavigation(keySelected || '');
        }
        dispatch(setShowWarningModal(false));
    };

    useEffect(() => {
        if (location.pathname) {
            if (ConnectedRoutes.dashboard.includes(location.pathname)) {
                dispatch(setKeySelected(HeaderConstants.dashboard.eventKey));
            }
            if (
                ConnectedRoutes.buildShipment.some(route =>
                    location.pathname.includes(route),
                )
            ) {
                dispatch(
                    setKeySelected(HeaderConstants.buildShipment.eventKey),
                );
            }
            if (ConnectedRoutes.loadBoard.includes(location.pathname)) {
                dispatch(setKeySelected(HeaderConstants.loadBoard.eventKey));
            }
            if (ConnectedRoutes.customer.includes(location.pathname)) {
                dispatch(setKeySelected(HeaderConstants.customer.eventKey));
            }
            if (ConnectedRoutes.driver.includes(location.pathname)) {
                dispatch(setKeySelected(HeaderConstants.driver.eventKey));
            }
            if (ConnectedRoutes.settings.includes(location.pathname)) {
                dispatch(setKeySelected(HeaderConstants.settings.eventKey));
            }
            if (ConnectedRoutes.reports.includes(location.pathname)) {
                dispatch(setKeySelected(HeaderConstants.report.eventKey));
            }
            if (ConnectedRoutes.support.includes(location.pathname)) {
                dispatch(setKeySelected(HeaderConstants.support.eventKey));
            }
            if (ConnectedRoutes.activityLog.includes(location.pathname)) {
                dispatch(setKeySelected(HeaderConstants.activityLog.eventKey));
            }
        }
    }, [location.pathname, dispatch]);

    const handleNavigation = (eventKey: string | null): void => {
        switch (eventKey) {
            case HeaderConstants.dashboard.eventKey:
                navigate(Routes.dashboard);
                break;
            case HeaderConstants.buildShipment.eventKey:
                navigate(Routes.buildShipment);
                break;
            case HeaderConstants.loadBoard.eventKey:
                navigate(Routes.loadBoard);
                break;
            case HeaderConstants.customer.eventKey:
                navigate(Routes.customers);
                break;
            case HeaderConstants.driver.eventKey:
                navigate(Routes.drivers);
                break;
            case HeaderConstants.settings.eventKey:
                navigate(Routes.settings);
                break;
            case HeaderConstants.report.eventKey:
                navigate(Routes.reports);
                break;
            case HeaderConstants.support.eventKey:
                navigate(Routes.support);
                break;
            case HeaderConstants.activityLog.eventKey:
                navigate(Routes.activityLog);
                break;
        }
    };

    const getHeaderName = (headerName: string): string => {
        if (
            (t(headerName) === t(HeaderConstants.userProfile.keyName) ||
                t(headerName) === t(HeaderConstants.settings.keyName) ||
                t(headerName) === t(HeaderConstants.support.keyName)) &&
            isWebView
        ) {
            return '';
        } else {
            return headerName;
        }
    };

    const handleNavigationSelect: SelectCallback | undefined = (
        eventKey: string | null,
    ) => {
        if (hasUnsavedChanges) {
            dispatch(setShowWarningModal(true));

            if (!showWarningModal) {
                return;
            }
            dispatch(setUnsavedChanges(false));
        }
        if (eventKey) {
            setKeySelected(eventKey);
        }
        handleNavigation(eventKey);
        setExpanded(false);
    };

    useEffect(() => {
        if (keySelected !== HeaderConstants.loadBoard.eventKey) {
            dispatch(resetLoadBoardShipmentDetails());
        }
    }, [keySelected, dispatch]);

    return {
        handleConfirmationModal,
        handleNavigationSelect,
        getHeaderName,
        expanded,
        setExpanded,
    };
};
