import { FC } from 'react';
import RollOnDispatch from './RollOnDispatch.svg';

const RollOnDispatchIcon: FC<{
    width: string;
    height: string;
}> = props => {
    return <img {...props} src={RollOnDispatch} alt="RollOnDispatchIcon" />;
};

export default RollOnDispatchIcon;
