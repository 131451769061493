import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PRIVATE_ROUTES, PUBLIC_ROUTES, Routes } from '../../utils/routes';
import { useAppDispatch } from '../../redux/hooks';
import { transformString } from '../../utils/functions';
import {
    setPermissionList,
    setTenantId,
    setUserRole,
} from '../../services/auth/reducer';

interface IUseAuthentication {
    isAuthenticated: boolean;
}

export const useAuthentication = (): IUseAuthentication => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const tokenExists =
        sessionStorage.getItem('token') || localStorage.getItem('token');
    const isAuthenticated = tokenExists ? true : false;
    const featureList =
        sessionStorage?.getItem('actionPermissions') ||
        localStorage?.getItem('actionPermissions') ||
        '';

    const formattedFeatureList = transformString(featureList);

    useEffect(() => {
        const isPrivateRoute = PRIVATE_ROUTES.some(route =>
            location.pathname.startsWith(route.path + '/'),
        );
        const isPublicRoute = PUBLIC_ROUTES.some(
            route => route.path === location.pathname,
        );

        if (!isAuthenticated) {
            if (
                isPrivateRoute ||
                location.pathname === '/' ||
                PRIVATE_ROUTES.find(route => route.path === location.pathname)
            ) {
                navigate(Routes.login);
            }
        } else if (isPublicRoute) {
            navigate('/');
        } else {
            dispatch(
                setTenantId(
                    localStorage.getItem('tenantId') ||
                        sessionStorage.getItem('tenantId') ||
                        '',
                ),
            );
            dispatch(
                setUserRole(
                    localStorage.getItem('userRole') ||
                        sessionStorage.getItem('userRole') ||
                        '',
                ),
            );
            dispatch(setPermissionList(formattedFeatureList));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, location.pathname, isAuthenticated, dispatch]);

    return {
        isAuthenticated,
    };
};
