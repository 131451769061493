export const BrokerShipmentHistoryTitles = [
    'Date',
    'Customer Name',
    'Pick-Up',
    'Drop-Off',
    'Role',
    'Commodity',
    'Weight/Hd Ct',
    'Miles',
    'Rate Type',
    'Rate',
];

export const CustomerShipmentHistoryTitles = [
    'Date',
    'Contract/PO #',
    'Pick-Up',
    'Drop-Off',
    'Commodity',
    'Weight/Hd Ct',
    'Miles',
    'Rate Type',
    'Rate',
];

export const DriverShipmentHistoryTitles = [
    'Date',
    'Customer Name',
    'Pick-Up',
    'Drop-Off',
    'Commodity',
    'Weight/Hd Ct',
    'Miles',
    'Rate Type',
    'Rate',
];
