import { IShipmentLoadBoardViewItem } from '../../../api/shipment/interfaces';

export const getTrailerSize = (
    shipmentDetail: IShipmentLoadBoardViewItem,
    isAlterateDriver: boolean,
): string => {
    const trailerSizes = [];

    if (
        shipmentDetail._49kTrucksRequired &&
        shipmentDetail._49kTrucksRequired > 0
    ) {
        trailerSizes.push(
            `TrailerSize ${isAlterateDriver ? 'ne' : 'eq'} '_49k'`,
        );
    }

    if (
        shipmentDetail._52kTrucksRequired &&
        shipmentDetail._52kTrucksRequired > 0
    ) {
        trailerSizes.push(
            `TrailerSize ${isAlterateDriver ? 'ne' : 'eq'} '_52k'`,
        );
    }

    if (
        shipmentDetail._55_62kTrucksRequired &&
        shipmentDetail._55_62kTrucksRequired > 0
    ) {
        trailerSizes.push(
            `TrailerSize ${isAlterateDriver ? 'ne' : 'eq'} '_55_62k'`,
        );
    }

    if (trailerSizes.length > 0) {
        return 'and (' + trailerSizes.join(' or ') + ')';
    }

    return '';
};
