import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../utils/routes';

export const Support: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div
            className={`cursor-pointer support-icon ${
                location.pathname.includes(Routes.support)
                    ? 'support-icon-active'
                    : ''
            }`}
            onClick={() => {
                navigate(Routes.support);
            }}
        >
            <FontAwesomeIcon icon={faQuestionCircle} />
        </div>
    );
};
