export const DriverActionsHeader = [
    {
        name: 'FullActivityLog:driverName',
    },
    {
        name: 'FullActivityLog:type',
    },
    {
        name: 'FullActivityLog:orderNumber',
    },
    {
        name: 'FullActivityLog:time',
    },
    {
        name: 'FullActivityLog:customerName',
    },
    {
        name: 'FullActivityLog:dropOff',
    },
    {
        name: '',
    },
];

export const driverActionDummyData = [
    {
        driverName: 'John Doe',
        type: 'Pickup',
        orderNumber: 'ORD123456',
        time: '2024-11-20 10:15 AM',
        customerName: 'Acme Corp',
        dropOff: 'Warehouse 5, Los Angeles, CA',
    },
    {
        driverName: 'Jane Smith',
        type: 'Delivery',
        orderNumber: 'ORD789012',
        time: '2024-11-20 02:30 PM',
        customerName: 'Global Industries',
        dropOff: 'Distribution Center, Chicago, IL',
    },
    {
        driverName: 'Carlos Martinez',
        type: 'Return',
        orderNumber: 'ORD345678',
        time: '2024-11-20 04:45 PM',
        customerName: 'Green Valley Organics',
        dropOff: 'Processing Plant, Denver, CO',
    },
    {
        driverName: 'Aisha Khan',
        type: 'Exchange',
        orderNumber: 'ORD901234',
        time: '2024-11-20 09:20 AM',
        customerName: 'Blue Sky Retailers',
        dropOff: 'Retail Store, Austin, TX',
    },
    {
        driverName: 'Liam Brown',
        type: 'Pickup',
        orderNumber: 'ORD567890',
        time: '2024-11-20 11:10 AM',
        customerName: 'Sunrise Logistics',
        dropOff: 'Hub 2, Miami, FL',
    },
];
