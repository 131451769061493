import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { dateFormatter } from '../../../utils/functions';
import { NullableDate } from '../../../utils/interfaces';
import { reportApi } from '../../../api/report/reportApi';

export const fetchCommissionReport = createAsyncThunk(
    'fetchCommissionReport',
    async (
        {
            startDate,
            endDate,
        }: { startDate: NullableDate; endDate: NullableDate },
        { rejectWithValue },
    ) => {
        try {
            const formattedStartDate = dateFormatter(startDate || '');
            const formattedEndDate = dateFormatter(endDate || '');

            const response = await reportApi.fetchCommissionReport({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            });

            return response;
        } catch {
            return rejectWithValue('error');
        }
    },
);

interface ICommissionReportInitialState {
    loading: boolean;
    commissionReportDetail: Blob | null;
    status: APIStatus;
}

const initialState: ICommissionReportInitialState = {
    loading: false,
    commissionReportDetail: null,
    status: APIStatusOK,
};

export const fetchCommissionReportSlice = createSlice({
    name: 'fetchCommissionReport',
    initialState,
    reducers: {
        setCommissionReportDetail: (
            state,
            action: PayloadAction<Blob | null>,
        ) => {
            state.commissionReportDetail = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCommissionReport.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchCommissionReport.fulfilled, (state, action) => {
            state.loading = false;
            state.commissionReportDetail = action.payload;
        });
        builder.addCase(fetchCommissionReport.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

export const { setCommissionReportDetail } = fetchCommissionReportSlice.actions;

const CommissionReportReducer = fetchCommissionReportSlice.reducer;
export default CommissionReportReducer;
