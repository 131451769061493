import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IShipmentLoadBoardViewItem } from '../../../../../api/shipment/interfaces';
import { DRAFT_SHIPMENTDETAILS_INITIAL_STATE } from '../initialState';

const initialState: {
    draftShipmentDetail: IShipmentLoadBoardViewItem;
} = {
    draftShipmentDetail: DRAFT_SHIPMENTDETAILS_INITIAL_STATE,
};

interface UpdateDraftShipmentPayload {
    payload: Partial<IShipmentLoadBoardViewItem>;
    selectedShipmentDetail: IShipmentLoadBoardViewItem;
}

export const draftShipmentSlice = createSlice({
    name: 'draftShipment',
    initialState,
    reducers: {
        setDraftShipmentDetails: (
            state,
            action: PayloadAction<IShipmentLoadBoardViewItem>,
        ) => {
            state.draftShipmentDetail = action.payload;
        },
        updateDraftShipmentDetail: (
            state,
            action: PayloadAction<UpdateDraftShipmentPayload>,
        ) => {
            const { payload, selectedShipmentDetail } = action.payload;

            const updatedDriverLoads =
                state.draftShipmentDetail.driverLoads.map((load, index) => {
                    const selectedDriverLoad =
                        selectedShipmentDetail.driverLoads[index];
                    const isRateVerified =
                        selectedDriverLoad?.isRateVerified === false
                            ? payload.driverLoads?.[index]?.isRateVerified ??
                              load.isRateVerified
                            : load.isRateVerified;
                    return {
                        ...load,
                        ...(payload.driverLoads?.[index] ?? {}),
                        isRateVerified,
                    };
                });

            const updatedShipmentDetails = {
                ...state.draftShipmentDetail,
                ...payload,
                driverLoads: updatedDriverLoads,
            };

            state.draftShipmentDetail = updatedShipmentDetails;
        },
    },
});

export const { setDraftShipmentDetails, updateDraftShipmentDetail } =
    draftShipmentSlice.actions;

const DraftShipmentReducer = draftShipmentSlice.reducer;
export default DraftShipmentReducer;
