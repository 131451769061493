import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { dashboardApi } from '../../../api/dashboard/dashboardApi';
import { RangeValue } from '../../../components/Form/DateRangeInputField/DateRangeInputField';

export const fetchDashboardReports = createAsyncThunk(
    'fetchDashboardReports',
    async ({ dateRange }: { dateRange: RangeValue }, { rejectWithValue }) => {
        try {
            return await dashboardApi.fetchDashboardReports(dateRange);
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export interface IMontlyLoad {
    month: string;
    myLoads: number;
    brokerLoads: number;
    totalLoads: number;
}

export interface IFetchDashboardReportsInitialState {
    loading: boolean;
    monthlyLoads: IMontlyLoad[];
    status: APIStatus;
}

const initialState: IFetchDashboardReportsInitialState = {
    loading: false,
    monthlyLoads: [],
    status: APIStatusOK,
};

export const DashboardReportsSlice = createSlice({
    name: 'fetchDashboardReports',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchDashboardReports.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchDashboardReports.fulfilled, (state, action) => {
            state.loading = false;
            state.monthlyLoads = action.payload || [];
        });
        builder.addCase(fetchDashboardReports.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

const DashboardReportsReducer = DashboardReportsSlice.reducer;
export default DashboardReportsReducer;
