import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from '../../../../utils/routes';
import '../../style.css';

export const SettingsSelect: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div
            className={`settings-select-icon cursor-pointer ${
                location.pathname.includes(Routes.settings)
                    ? 'settings-select-icon-active'
                    : ''
            }`}
            onClick={() => {
                navigate(Routes.settings);
            }}
        >
            <FontAwesomeIcon icon={faGear} />
        </div>
    );
};
