import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { shipmentsApi } from '../../../api/shipment/shipmentsApi';
import { IShipmentHistorySearchFilters } from '../interface/IShipmentHistorySearchFilters';
import { AssociateType } from '../../Associate/enum';
import { IShipment } from '../../../api/shipment/interfaces';

export const fetchShipmentHistory = createAsyncThunk(
    'fetchShipmentHistory',
    async (
        {
            associateId,
            associateType,
            deliveryStartDate,
            deliveryEndDate,
        }: {
            associateId: string | number;
            associateType: AssociateType;
            deliveryStartDate?: string | Date;
            deliveryEndDate?: string | Date;
        },
        { rejectWithValue },
    ) => {
        try {
            return await shipmentsApi.fetchFullShipmentHistory(
                associateId,
                associateType,
                deliveryStartDate,
                deliveryEndDate,
            );
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export interface IShipmentHistoryInitialState {
    loading: boolean;
    shipmentHistoryList: IShipment[];
    shipmentHistorySearchFilters: IShipmentHistorySearchFilters;
    status: APIStatus;
}

const initialState: IShipmentHistoryInitialState = {
    loading: false,
    shipmentHistoryList: [],
    shipmentHistorySearchFilters: {
        deliveryStartDate: '',
        deliveryEndDate: '',
    },
    status: APIStatusOK,
};

export const fetchShipmentHistorySlice = createSlice({
    name: 'fetchShipmentHistory',
    initialState,
    reducers: {
        setShipmentHistoryList: (state, action: PayloadAction<IShipment[]>) => {
            state.shipmentHistoryList = action.payload;
        },
        setShipmentHistorySearchFilters: (
            state,
            action: PayloadAction<IShipmentHistorySearchFilters>,
        ) => {
            state.shipmentHistorySearchFilters = action.payload;
        },
        resetShipmentHistorySearchFilters: state => {
            state.shipmentHistorySearchFilters =
                initialState.shipmentHistorySearchFilters;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchShipmentHistory.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchShipmentHistory.fulfilled, (state, action) => {
            state.loading = false;
            state.shipmentHistoryList = action.payload;
        });
        builder.addCase(fetchShipmentHistory.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

export const {
    setShipmentHistoryList,
    setShipmentHistorySearchFilters,
    resetShipmentHistorySearchFilters,
} = fetchShipmentHistorySlice.actions;

const ShipmentHistoryReducer = fetchShipmentHistorySlice.reducer;
export default ShipmentHistoryReducer;
