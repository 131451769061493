import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITagGroup } from '../../api/interfaces';
import { tagGroupApi } from '../../api/tagGroup/tagGroup';

export interface ITagGroupInitialState {
    loading: boolean;
    tagGroups: ITagGroup[];
}

const initialState: ITagGroupInitialState = {
    loading: false,
    tagGroups: [],
};

export const fetchTagGroups = createAsyncThunk(
    'tagGroups/fetchTagGroups',
    async (_, { rejectWithValue }) => {
        try {
            return await tagGroupApi.fetchTagGroups();
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export const tagGroupSlice = createSlice({
    name: 'taggroup',
    initialState,
    reducers: {
        setTagGroups: (state, action: PayloadAction<ITagGroup[]>) => {
            state.tagGroups = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchTagGroups.pending, state => {
            state.loading = true;
            state.tagGroups = [];
        });
        builder.addCase(fetchTagGroups.fulfilled, (state, action) => {
            state.loading = false;
            state.tagGroups = action.payload;
        });
        builder.addCase(fetchTagGroups.rejected, state => {
            state.loading = false;
        });
    },
});

export const { setTagGroups } = tagGroupSlice.actions;

const TagGroupReducer = tagGroupSlice.reducer;
export default TagGroupReducer;
