import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { dashboardApi } from '../../../api/dashboard/dashboardApi';

export const fetchDashboardStats = createAsyncThunk(
    'fetchDashboardStats',
    async (_, { rejectWithValue }) => {
        try {
            return await dashboardApi.fetchDashboardStats();
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export interface IShipmentStatusCount {
    name: string;
    count: number;
}

export interface ILoadsCount {
    driverLoadsCount: number;
    brokerLoadsCount: number;
    totalLoads: number;
}

export interface IFetchDashoardStatsResponse {
    shipmentStatusCount: IShipmentStatusCount[];
    loadsCount: ILoadsCount;
    currentBreakdowns: {
        count: number;
        breakDownDriversName: string[];
    };
}

export interface IFetchDashboardStatsInitialState {
    loading: boolean;
    shipmentStatusCount: IShipmentStatusCount[];
    loadsCount: ILoadsCount;
    currentBreakdowns: {
        count: number;
        breakDownDriversName: string[];
    };
    status: APIStatus;
}

const initialState: IFetchDashboardStatsInitialState = {
    loading: false,
    shipmentStatusCount: [
        { name: 'AwaitingConfirmation', count: 0 },
        { name: 'ContinueBuilding', count: 0 },
        { name: 'Delivered', count: 0 },
        { name: 'Dispatched', count: 0 },
        { name: 'NeedsDrivers', count: 0 },
        { name: 'PastLoads', count: 0 },
        { name: 'Ready', count: 0 },
        { name: 'ReadyForInvoicing', count: 0 },
    ],
    loadsCount: {
        driverLoadsCount: 0,
        brokerLoadsCount: 0,
        totalLoads: 0,
    },
    currentBreakdowns: {
        count: 0,
        breakDownDriversName: [],
    },
    status: APIStatusOK,
};

export const DashboardStatsSlice = createSlice({
    name: 'fetchDashboardStats',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchDashboardStats.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchDashboardStats.fulfilled, (state, action) => {
            state.loading = false;
            state.shipmentStatusCount = action.payload.shipmentStatusCount;
            state.loadsCount = action.payload.loadsCount;
            state.currentBreakdowns = action.payload.currentBreakdowns;
        });
        builder.addCase(fetchDashboardStats.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

const DashboardStatsReducer = DashboardStatsSlice.reducer;
export default DashboardStatsReducer;
