import { useEffect, useState } from 'react';
import { WINDOW_THRESHOLD_WIDTH } from '../constants/common';

export const useIsWebView = (): boolean => {
    const [isWebView, setIsWebView] = useState(
        window.innerWidth > WINDOW_THRESHOLD_WIDTH,
    );

    useEffect(() => {
        const handleResize = (): void => {
            const isNewWebView = window.innerWidth > WINDOW_THRESHOLD_WIDTH;
            if (isNewWebView !== isWebView) {
                setIsWebView(isNewWebView);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isWebView]);

    return isWebView;
};
