import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type SettingsAttachmentDetailInitialState = {
    settingsAttachmentDetail: File | string;
};

export const settingsAttachmentDetail = createSlice({
    name: 'settingsAttachmentDetail',
    initialState: {
        settingsAttachmentDetail: '',
    } as SettingsAttachmentDetailInitialState,
    reducers: {
        setSettingsAttachmentDetail: (
            state,
            action: PayloadAction<File | string>,
        ) => {
            state.settingsAttachmentDetail = action.payload;
        },
    },
});

export const { setSettingsAttachmentDetail } = settingsAttachmentDetail.actions;

const SettingsAttachmentReducer = settingsAttachmentDetail.reducer;
export default SettingsAttachmentReducer;
