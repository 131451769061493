import { AssociateType } from '../../components/Associate/enum';
import { CustomerListUtils } from '../../pages/Customer/utils/CustomerListUtils';
import { API_CONSTANTS } from '../../services/apiConstants';
import { DriverUtils } from '../../utils/DriverUtils';
import { dateFormatter, formatDate } from '../../utils/functions';
import { IGenericApiResponse } from '../../utils/interfaces';
import { apiCall } from '../axios';
import { IDriver, IDriverCard, IDriverDetail } from '../driver/interfaces';
import { IAttachment, IAttachmentResponse } from '../interfaces';
import { IAssociate } from './interfaces';
import { IBreakdownDriverPayload } from './interfaces/IBreakdownDriverPayload';

export const createAssociate = async (
    associate: IAssociate,
): Promise<IGenericApiResponse<number>> => {
    const { attachments, ...newAssociate } = associate;
    const response: IGenericApiResponse<number> = await apiCall(
        'post',
        `${
            API_CONSTANTS.associates +
            (newAssociate.associateType === AssociateType.Customer
                ? API_CONSTANTS.customers
                : API_CONSTANTS.brokers)
        }`,
        newAssociate,
        true,
    );

    return response;
};

export const uploadAttachment = async (
    attachment: IAttachment,
): Promise<IAttachmentResponse> => {
    const formData = new FormData();

    formData.append('Id', attachment.Id?.toString());
    formData.append('File', attachment.Attachment);
    attachment.Expiry &&
        formData.append('Expiry', dateFormatter(attachment.Expiry));
    attachment.Type && formData.append('Type', attachment.Type);

    const response: IGenericApiResponse<IAttachmentResponse> = await apiCall(
        'post',
        `${API_CONSTANTS.associates + API_CONSTANTS.attachments}`,
        formData,
        true,
        false,
        true,
    );

    return response.data;
};

export const deleteAttachment = async (
    attachmentId: number | string,
    associateId: number | string,
): Promise<void> => {
    const response = await apiCall(
        'delete',
        `${API_CONSTANTS.associates}/${associateId}${API_CONSTANTS.attachments}/${attachmentId}`,
        {},
        true,
    );
    return response;
};

export const fetchAllDrivers = async (
    discriminator: string,
): Promise<IGenericApiResponse<IDriverCard[]>> => {
    const response: IGenericApiResponse<IDriverCard[]> = await apiCall(
        'get',
        `${API_CONSTANTS.associates}?include=Address,TagGroups,Attachments,Destination,Unavailabilities&filter=isActive eq true; ${discriminator}&sort=name`,
        {},
        true,
    );

    return response;
};

export const fetchAlterateDrivers = async (
    tagGroupId: string,
    associateIds: string,
): Promise<IGenericApiResponse<IDriverCard[]>> => {
    const response: IGenericApiResponse<IDriverCard[]> = await apiCall(
        'get',
        `${API_CONSTANTS.associates}/odata?include=Address,TagGroups,Attachments,Destination,Unavailabilities&filter=(Discriminator eq 'Driver') and (isActive eq true) ${tagGroupId}) ${associateIds}&sort=name`,
        {},
        true,
    );

    return response;
};

export const fetchSuggestedDrivers = async (
    tagGroupId: string,
    trailerSize: string,
): Promise<IGenericApiResponse<IDriverCard[]>> => {
    const response: IGenericApiResponse<IDriverCard[]> = await apiCall(
        'get',
        `${API_CONSTANTS.associates}/odata?include=Address,TagGroups,Attachments,Destination,Unavailabilities&filter=(Discriminator eq 'Driver') and (isActive eq true) ${tagGroupId} ${trailerSize}) or (Discriminator eq 'Broker') ${tagGroupId}) &sort=name`,
        {},
        true,
    );

    return response;
};

export const fetchDriverList = async (
    currentLocation?: string,
    status?: boolean,
    driverName?: string,
): Promise<IGenericApiResponse<IDriverCard[]>> => {
    const driverUtils = new DriverUtils();
    let url = `${API_CONSTANTS.associates}/odata?include=Address,TagGroups,Attachments,Destination,Unavailabilities&orderby=Name asc`;

    url += driverUtils.driverListFilterQueryString(
        currentLocation,
        status,
        driverName,
    );

    const response: IGenericApiResponse<IDriverCard[]> = await apiCall(
        'get',
        url,
        {},
        true,
    );
    return response;
};

export const fetchCustomerList = async (
    top?: number,
    skip?: number,
    customerName?: string,
    primaryContact?: string,
    isShowBrokersOnlySelected?: boolean,
): Promise<IGenericApiResponse<IAssociate[]>> => {
    const customerListUtils = new CustomerListUtils();
    let url = `${API_CONSTANTS.associates}/odata?include=Contacts,Address&orderby=Name asc`;

    if (top !== undefined) {
        url += `&$top=${top}`;
    }

    if (skip !== undefined) {
        url += `&$skip=${skip}`;
    }

    url += customerListUtils.customerListFilterQueryString(
        customerName,
        primaryContact,
        isShowBrokersOnlySelected,
    );

    const response: IGenericApiResponse<IAssociate[]> = await apiCall(
        'get',
        url,
        {},
        true,
    );
    return response;
};

export const fetchAssociateDetail = async (
    associateId: number | string,
    associateIncludes?: string,
): Promise<IGenericApiResponse<IAssociate>> => {
    const response: IGenericApiResponse<IAssociate> = await apiCall(
        'get',
        `${API_CONSTANTS.associates}/${associateId}?include=Contacts,Address,AssociatePinDrops.PinDrop.Location,${associateIncludes},Attachments`,
        {},
        true,
    );
    return response;
};

export const updateCustomerDetail = async (
    customerId: string | number,
    payload: Partial<IAssociate>,
): Promise<number> => {
    const response = await apiCall(
        'put',
        `${API_CONSTANTS.associates}${API_CONSTANTS.customers}/${customerId}`,
        payload,
        true,
    );
    return response.status;
};

export const updateBrokerDetail = async (
    brokerId: string | number,
    payload: Partial<IAssociate>,
): Promise<number> => {
    payload.attachments?.length && (payload.attachments = []);
    const response = await apiCall(
        'put',
        `${API_CONSTANTS.associates}${API_CONSTANTS.brokers}/${brokerId}`,
        payload,
        true,
    );
    return response.status;
};

export const updateDriver = async (
    driver: Partial<IDriverCard>,
): Promise<void> => {
    await apiCall(
        'put',
        `${API_CONSTANTS.associates}${API_CONSTANTS.drivers}/${driver.associateId}`,
        { ...driver },
        true,
    );
};

export const updateAssociateDetail = async (
    payload: Partial<IAssociate | IDriverDetail>,
    associateType: string,
): Promise<void> => {
    await apiCall(
        'put',
        `${API_CONSTANTS.associates}${associateType}/${payload.associateId}`,
        payload,
        true,
    );
};

export const fetchAssociateAttachment = async (
    associateId: number,
    attachment: IAttachmentResponse,
): Promise<IGenericApiResponse<Blob>> => {
    const response: IGenericApiResponse<Blob> = await apiCall(
        'get',
        `${API_CONSTANTS.associates}/${associateId}${API_CONSTANTS.attachments}/${attachment.attachmentId}/${attachment.accessToken}`,
        {},
        true,
        true,
    );

    return response;
};

export const updateDriverBreakdown = async (
    breakdownPayload: IBreakdownDriverPayload,
): Promise<number> => {
    const response: IGenericApiResponse<number> = await apiCall(
        'post',
        `${API_CONSTANTS.associates + API_CONSTANTS.drivers}/${
            breakdownPayload.driverId + API_CONSTANTS.breakdown
        }`,
        breakdownPayload,
        true,
    );

    return response.data;
};

export const updateDriverResolveBreakdown = async (
    driverId: number,
): Promise<number> => {
    const response: IGenericApiResponse<number> = await apiCall(
        'put',
        `${API_CONSTANTS.associates}/${driverId}`,
        driverId,
        true,
    );

    return response.data;
};

export const fetchRescueDrivers = async (
    startDate: string,
): Promise<IDriver[]> => {
    const response: IGenericApiResponse<IDriver[]> = await apiCall(
        'get',
        `${
            API_CONSTANTS.associates
        }/odata?include=Address,TagGroups,Attachments,Destination,Unavailabilities,Driver,AssociateAttachment&orderby=Name
        asc&$filter=IsActive eq true and
        ((Discriminator eq 'Driver' or Discriminator eq 'Broker') and
        (not (attachments/any()) or attachments/all(a: a/Expiry gt ${formatDate(
            new Date(),
            'YYYY-MM-DD',
        )}T00:00:00Z))) and
        (IsBrokenDown eq false or IsBrokenDown eq null) and(not (WebApp.Data.Entities.Driver/Unavailabilities/any())
        or ( WebApp.Data.Entities.Driver/Unavailabilities/any(u: u/StartDate gt ${formatDate(
            startDate,
            'YYYY-MM-DD',
        )}T00:00:00Z )))`,
        {},
        true,
    );

    return response.data;
};

const associateApi = {
    createAssociate,
    uploadAttachment,
    deleteAttachment,
    fetchAlterateDrivers,
    fetchSuggestedDrivers,
    fetchAllDrivers,
    fetchCustomerList,
    fetchDriverList,
    updateDriver,
    fetchAssociateDetail,
    updateCustomerDetail,
    updateBrokerDetail,
    updateAssociateDetail,
    fetchAssociateAttachment,
    updateDriverBreakdown,
    updateDriverResolveBreakdown,
    fetchRescueDrivers,
};

export default associateApi;
