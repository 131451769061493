import { RangeValue } from '../../../../../components/Form/DateRangeInputField/DateRangeInputField';
import {
    dateFormatter,
    encodeSpecialCharacters,
} from '../../../../../utils/functions';

export const searchDateFilterDetailsMap = new Map([
    [
        'orderDate',
        {
            getFilter: (value: RangeValue): string => {
                if (Array.isArray(value)) {
                    const startTimestamp = value[0];
                    const endTimestamp = value[1];

                    if (startTimestamp && endTimestamp) {
                        return `(DeliveryStartDate ge ${dateFormatter(
                            startTimestamp,
                        )}) and (DeliveryStartDate le ${dateFormatter(
                            endTimestamp,
                        )})`;
                    }
                }
                return '';
            },
        },
    ],
]);

export const searchFilterDetailsMap = new Map([
    [
        'customerName',
        {
            getFilter: (value: string): string =>
                `(contains(Associate/Name, '${encodeSpecialCharacters(
                    value,
                )}'))`,
        },
    ],
    [
        'contractNumber',
        {
            getFilter: (value: string): string =>
                `ContractNumber eq '${encodeSpecialCharacters(value)}'`,
        },
    ],
    [
        'driverName',
        {
            getFilter: (value: string): string =>
                `DriverLoads/any(driverLoad: contains(driverLoad/Associate/Name, '${encodeSpecialCharacters(
                    value,
                )}'))`,
        },
    ],
    [
        'orderNumber',
        {
            getFilter: (value: string): string =>
                `DriverLoads/any(driverLoad: driverLoad/OrderNumber eq '${encodeSpecialCharacters(
                    value,
                )}')`,
        },
    ],
]);
