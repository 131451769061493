export const ShipmentStatusTypes = {
    continueBuilding: 'ContinueBuilding',
    needsDrivers: 'NeedsDrivers',
    ready: 'Ready',
    awaitingConfirmation: 'AwaitingConfirmation',
    pending: 'Pending',
    dispatched: 'Dispatched',
    delivered: 'Delivered',
    readyForInvoicing: 'ReadyForInvoicing',
    pastLoads: 'PastLoads',
};
