import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import associateApi from '../../../api/associate/associateApi';
import { setAssociate } from './createAssociate';
import { ISingleValueSelect } from '../../../utils/interfaces';
import { ASSOCIATE_DETAIL_INITIAL_STATE } from '../initialState';
import { IAssociate, IContact } from '../../../api/associate/interfaces';
import { IAssociateAttachment } from '../../../pages/Driver/DriverDetails/interfaces/IAssociateAttachment';

export const formatPhoneNumberWithExtension = (
    phoneNumberWithExtension: string,
): {
    phoneNumber: string;
    extension: string;
} => {
    const [phoneNumber, extension] = phoneNumberWithExtension.split('-');
    return { phoneNumber, extension };
};

const reorderContacts = (contacts: IContact[]): IContact[] => {
    const primaryContactIndex = contacts.findIndex(
        contact => contact.isPrimary === true,
    );
    if (primaryContactIndex > 0) {
        const primaryContact = contacts[primaryContactIndex];
        contacts.splice(primaryContactIndex, 1);
        contacts.unshift(primaryContact);
    }
    return contacts;
};

export const formatContacts = (contacts: IContact[]): IContact[] => {
    return contacts.map(contact => {
        const { phoneNumber: contactPhoneNumber, extension: contactExtension } =
            formatPhoneNumberWithExtension(contact.phoneNumber);
        return {
            ...contact,
            phoneNumber: contactPhoneNumber,
            ext: contactExtension,
        };
    });
};

export const fetchAssociateDetail = createAsyncThunk(
    'fetchAssociateDetail',
    async (
        {
            associateId,
            associateIncludes,
            unsavedAssociateAttachment,
        }: {
            associateId: string | number;
            associateIncludes?: string;
            unsavedAssociateAttachment?: Array<IAssociateAttachment>;
        },
        { rejectWithValue, dispatch },
    ) => {
        try {
            const response = await associateApi.fetchAssociateDetail(
                associateId,
                associateIncludes,
            );

            const { phoneNumber, extension } = formatPhoneNumberWithExtension(
                response.data.phoneNumber,
            );

            const updatedContacts = reorderContacts(
                formatContacts(response.data.contacts),
            );

            dispatch(
                setAssociate({
                    ...response.data,
                    phoneNumber: phoneNumber,
                    ext: extension,
                    contacts: updatedContacts,
                    attachments:
                        unsavedAssociateAttachment?.length !== undefined &&
                        unsavedAssociateAttachment.length > 0
                            ? [
                                  ...unsavedAssociateAttachment,
                                  ...response.data.attachments,
                              ]
                            : response.data.attachments,
                }),
            );
            dispatch(
                setSelectedAssociateState({
                    value: response.data.address?.state || '',
                    label: response.data?.address?.state || '',
                }),
            );

            return response.data;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export const associateDetailSlice = createSlice({
    name: 'fetchAssociateDetail',
    initialState: ASSOCIATE_DETAIL_INITIAL_STATE,
    reducers: {
        setSelectedAssociateState: (
            state,
            action: PayloadAction<ISingleValueSelect>,
        ) => {
            state.selectedAssociateState = action.payload;
        },
        setSelectedAssociateDetail: (
            state,
            action: PayloadAction<IAssociate>,
        ) => {
            state.selectedAssociateDetail = action.payload;
        },
        setUpdatedAssociateDetail: (
            state,
            action: PayloadAction<IAssociate>,
        ) => {
            state.updatedAssociateDetail = action.payload;
        },
        setSourceRoute: (state, action: PayloadAction<string>) => {
            state.sourceRoute = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchAssociateDetail.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchAssociateDetail.fulfilled, (state, action) => {
            const { phoneNumber, extension } = formatPhoneNumberWithExtension(
                action.payload.phoneNumber,
            );
            const updatedContacts = formatContacts(action.payload.contacts);
            state.loading = false;
            state.associateDetail = action.payload;
            state.selectedAssociateDetail = {
                ...action.payload,
                phoneNumber: phoneNumber,
                ext: extension,
                contacts: updatedContacts,
            };
            state.updatedAssociateDetail = {
                ...action.payload,
                phoneNumber: phoneNumber,
                ext: extension,
                contacts: updatedContacts,
            };
        });

        builder.addCase(fetchAssociateDetail.rejected, state => {
            state.loading = false;
        });
    },
});

export const {
    setSelectedAssociateState,
    setUpdatedAssociateDetail,
    setSourceRoute,
} = associateDetailSlice.actions;

const AssociateDetailReducer = associateDetailSlice.reducer;
export default AssociateDetailReducer;
