import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { dateFormatter } from '../../../utils/functions';
import { NullableDate } from '../../../utils/interfaces';
import { reportApi } from '../../../api/report/reportApi';

export const fetchDeathLossReport = createAsyncThunk(
    'fetchDeathLossReport',
    async (
        {
            startDate,
            endDate,
        }: { startDate: NullableDate; endDate: NullableDate },
        { rejectWithValue },
    ) => {
        try {
            const formattedStartDate = dateFormatter(startDate || '');
            const formattedEndDate = dateFormatter(endDate || '');

            const response = await reportApi.fetchDeathLossReport({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            });

            return response;
        } catch {
            return rejectWithValue('error');
        }
    },
);

interface IDeathLossReportInitialState {
    loading: boolean;
    deathLossReportDetail: Blob | null;
    status: APIStatus;
}

const initialState: IDeathLossReportInitialState = {
    loading: false,
    deathLossReportDetail: null,
    status: APIStatusOK,
};

export const fetchDeathLossReportSlice = createSlice({
    name: 'fetchDeathLossReport',
    initialState,
    reducers: {
        setDeathLossReportDetail: (
            state,
            action: PayloadAction<Blob | null>,
        ) => {
            state.deathLossReportDetail = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchDeathLossReport.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchDeathLossReport.fulfilled, (state, action) => {
            state.loading = false;
            state.deathLossReportDetail = action.payload;
        });
        builder.addCase(fetchDeathLossReport.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

export const { setDeathLossReportDetail } = fetchDeathLossReportSlice.actions;

const DeathLossReportReducer = fetchDeathLossReportSlice.reducer;
export default DeathLossReportReducer;
