export interface ICustomerListUtils {
    customerListFilterQueryString: (
        customerName?: string,
        primaryContact?: string,
        isShowBrokersOnlySelected?: boolean,
    ) => string;
}

export class CustomerListUtils implements ICustomerListUtils {
    customerListFilterQueryString = (
        customerName?: string,
        primaryContact?: string,
        isShowBrokersOnlySelected?: boolean,
    ): string => {
        let filterQuery = `(Discriminator eq 'Customer' or Discriminator eq 'Broker')`;

        if (isShowBrokersOnlySelected) {
            filterQuery = `(Discriminator eq 'Broker')`;
        }

        if (customerName) {
            filterQuery += `and (contains(Name, '${customerName}'))`;
        }

        if (primaryContact) {
            filterQuery += `and (Contacts/any(contact: contains(contact/Name, '${primaryContact}') and contact/IsPrimary eq true))`;
        }

        if (filterQuery) {
            filterQuery = `&$filter=${filterQuery}`;
        }

        return filterQuery;
    };
}
