import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISingleValueSelect } from '../../../utils/interfaces';

export interface IBreakDownDetails {
    selectedBreakdownDriver: ISingleValueSelect;
    selectedShipmentId: number;
    associatedShipment: JSX.Element[];
    associatedLocation: JSX.Element[];
    selectedLocationId: number;
    selectedRescueDriver: ISingleValueSelect;
    showBreakdownDriverModal: boolean;
    showRescueDriverModal: boolean;
    isRescueLocationSelected: boolean;
    breakdownShipmentDetailsLoading: boolean;
    shipmentStartDate: string;
}

const INITIAL_BREAKDOWN_DRIVER_DETAIL: IBreakDownDetails = {
    selectedBreakdownDriver: {
        value: '',
        label: '',
    },
    selectedShipmentId: 0,
    associatedShipment: [],
    associatedLocation: [],
    selectedLocationId: 0,
    selectedRescueDriver: {
        value: '',
        label: '',
    },
    showBreakdownDriverModal: false,
    showRescueDriverModal: false,
    isRescueLocationSelected: false,
    breakdownShipmentDetailsLoading: true,
    shipmentStartDate: '',
};

export const BreakdownModal = createSlice({
    name: 'BreakdownModal',
    initialState: INITIAL_BREAKDOWN_DRIVER_DETAIL,
    reducers: {
        setSelectedBreakdownDriver: (
            state,
            action: PayloadAction<ISingleValueSelect>,
        ) => {
            state.selectedBreakdownDriver = action.payload;
        },
        setSelectedRescueDriver: (
            state,
            action: PayloadAction<ISingleValueSelect>,
        ) => {
            state.selectedRescueDriver = action.payload;
        },
        setAssociatedShipment: (
            state,
            action: PayloadAction<JSX.Element[]>,
        ) => {
            state.associatedShipment = action.payload;
        },
        setSelectedShipmentId: (state, action: PayloadAction<number>) => {
            state.selectedShipmentId = action.payload;
        },
        setSelectedLocationId: (state, action: PayloadAction<number>) => {
            state.selectedLocationId = action.payload;
        },
        setAssociatedLocation: (
            state,
            action: PayloadAction<JSX.Element[]>,
        ) => {
            state.associatedLocation = action.payload;
        },
        setShowBreakdownDriverModal: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showBreakdownDriverModal = action.payload;
        },
        setShowRescueDriverModal: (state, action: PayloadAction<boolean>) => {
            state.showRescueDriverModal = action.payload;
        },
        setBreakdownShipmentDetailsLoading: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.breakdownShipmentDetailsLoading = action.payload;
        },
        setIsRescueLocationSelected: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.isRescueLocationSelected = action.payload;
        },
        setShipmentStartDate: (state, action: PayloadAction<string>) => {
            state.shipmentStartDate = action.payload;
        },
        resetAllBreakdownStates: () => {
            return {
                ...INITIAL_BREAKDOWN_DRIVER_DETAIL,
            };
        },
    },
});

export const {
    setSelectedBreakdownDriver,
    setAssociatedShipment,
    setSelectedShipmentId,
    setAssociatedLocation,
    setSelectedLocationId,
    resetAllBreakdownStates,
    setSelectedRescueDriver,
    setShowBreakdownDriverModal,
    setShowRescueDriverModal,
    setIsRescueLocationSelected,
    setBreakdownShipmentDetailsLoading,
    setShipmentStartDate,
} = BreakdownModal.actions;

const BreakdownDriverModalReducer = BreakdownModal.reducer;
export default BreakdownDriverModalReducer;
