import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICustomerResponse } from '../interface';
import { CUSTOMER_INITIAL_STATE } from '../initialState';
import { SingleValue } from 'react-select';
import { ISelect } from '../../../utils/interfaces';

export const customerDetailSlice = createSlice({
    name: 'customer',
    initialState: CUSTOMER_INITIAL_STATE,
    reducers: {
        setCustomerDetail: (
            state,
            action: PayloadAction<ICustomerResponse[]>,
        ) => {
            state.customersDetail = action.payload;
        },
        setCustomers: (state, action: PayloadAction<ISelect[]>) => {
            state.customers = action.payload;
        },
        setCustomerName: (
            state,
            action: PayloadAction<SingleValue<ISelect | undefined>>,
        ) => {
            state.selectedCustomer = action.payload;
        },
        resetAllCustomerStates: state => {
            return {
                ...CUSTOMER_INITIAL_STATE,
                customers: state.customers,
                customersDetail: state.customersDetail,
            };
        },
    },
});

export const {
    setCustomers,
    setCustomerDetail,
    setCustomerName,
    resetAllCustomerStates,
} = customerDetailSlice.actions;

const CustomerDeatilReducer = customerDetailSlice.reducer;
export default CustomerDeatilReducer;
