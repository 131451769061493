import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { pinDropApi } from '../../../api/PinDrop/pinDropApi';
import { IAssociatePinDrop } from '../../../api/associate/interfaces';

export const fetchPinDropByAssociateId = createAsyncThunk(
    'fetchPinDropByAssociateId',
    async ({ associateId }: { associateId: number }, { rejectWithValue }) => {
        try {
            const response = await pinDropApi.fetchPinDropByAssociateId(
                associateId,
            );
            return response;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export type IPinDropInitialState = {
    loading: boolean;
    pinDropList: IAssociatePinDrop[];
};

const initialState: IPinDropInitialState = {
    loading: false,
    pinDropList: [],
};

export const pinDropsSlice = createSlice({
    name: 'fetchPinDropByAssociateId',
    initialState,
    reducers: {
        setPinDropList: (state, action: PayloadAction<IAssociatePinDrop[]>) => {
            state.pinDropList = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchPinDropByAssociateId.pending, state => {
            state.loading = true;
            state.pinDropList = [];
        });
        builder.addCase(
            fetchPinDropByAssociateId.fulfilled,
            (state, action) => {
                state.loading = false;
                state.pinDropList = action.payload;
            },
        );
        builder.addCase(fetchPinDropByAssociateId.rejected, state => {
            state.loading = false;
        });
    },
});

export const { setPinDropList } = pinDropsSlice.actions;

const PinDropsReducer = pinDropsSlice.reducer;
export default PinDropsReducer;
