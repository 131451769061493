export const RecentActivityHeader = [
    {
        name: 'FullActivityLog:type',
    },
    {
        name: 'FullActivityLog:driver',
    },
    {
        name: 'FullActivityLog:date/time',
    },
    {
        name: '',
    },
];

export const dummyData = [
    {
        type: 'Pickup',
        driver: 'John Doe',
        dateTime: '2024-11-18T09:30:00Z',
    },
    {
        type: 'Dropoff',
        driver: 'Jane Smith',
        dateTime: '2024-11-18T14:00:00Z',
    },
    {
        type: 'Rescue',
        driver: 'Michael Johnson',
        dateTime: '2024-11-19T08:15:00Z',
    },
    {
        type: 'Pickup',
        driver: 'Alice Brown',
        dateTime: '2024-11-19T10:45:00Z',
    },
    {
        type: 'Dropoff',
        driver: 'Chris Evans',
        dateTime: '2024-11-19T15:30:00Z',
    },
    {
        type: 'Rescue',
        driver: 'Emma Wilson',
        dateTime: '2024-11-20T11:00:00Z',
    },
];
