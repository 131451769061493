import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import associateApi from '../../../api/associate/associateApi';
import { IDriverCard } from '../../../api/driver/interfaces';
import { IShipmentLoadBoardViewItem } from '../../../api/shipment/interfaces';
import { DriverUtils } from '../../../utils/DriverUtils';
import { getAssociateIds } from '../functions/getAssociateIds';
import { getTagGroupId } from '../functions/getTagGroupId';
import { IAlternateDriversInitialState } from '../interfaces/IAlternateDrivers';

export const fetchAlternateDrivers = createAsyncThunk(
    'fetchAlternateDrivers',
    async (
        {
            shipmentDetail,
            suggestedDriversAssociateId,
        }: {
            shipmentDetail: IShipmentLoadBoardViewItem;
            suggestedDriversAssociateId: number[];
        },
        { rejectWithValue },
    ) => {
        const tagGroupId = getTagGroupId(shipmentDetail);
        const associateIds = getAssociateIds(suggestedDriversAssociateId);
        const driverUtils = new DriverUtils();

        try {
            const response = await associateApi.fetchAlterateDrivers(
                tagGroupId,
                associateIds,
            );
            return driverUtils.formatDriverList(response.data);
        } catch {
            return rejectWithValue('Error');
        }
    },
);

const initialState: IAlternateDriversInitialState = {
    loading: false,
    alternateDrivers: [],
    filteredAlternateDrivers: [],
};

export const alternateDriversSlice = createSlice({
    name: 'fetchAlternateDrivers',
    initialState,
    reducers: {
        setAlternateDrivers: (state, action: PayloadAction<IDriverCard[]>) => {
            state.alternateDrivers = action.payload;
        },
        setFilteredAlternateDrivers: (
            state,
            action: PayloadAction<IDriverCard[]>,
        ) => {
            state.filteredAlternateDrivers = action.payload;
        },
        removeSelectedAlternateDriver: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.filteredAlternateDrivers =
                state.filteredAlternateDrivers.filter(
                    driver => driver.associateId !== action.payload,
                );
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchAlternateDrivers.pending, state => {
            state.loading = true;
            state.alternateDrivers = [];
        });
        builder.addCase(fetchAlternateDrivers.fulfilled, (state, action) => {
            state.loading = false;
            state.alternateDrivers = action.payload;
        });
        builder.addCase(fetchAlternateDrivers.rejected, state => {
            state.loading = false;
        });
    },
});

export const {
    setAlternateDrivers,
    setFilteredAlternateDrivers,
    removeSelectedAlternateDriver,
} = alternateDriversSlice.actions;

const AlternateDriversReducer = alternateDriversSlice.reducer;
export default AlternateDriversReducer;
