import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import associateApi from '../../../api/associate/associateApi';
import { IAssociate, IContact } from '../../../api/associate/interfaces';
import { notifySuccess } from '../../../api/axios';
import { NEW_ASSOCIATE_INITIAL_STATE } from '../initialState';
import { AssociateFormErrorState, IAssociateState } from '../interfaces';
import { IAttachment, ITagGroup } from '../../../api/interfaces';
import { ILocationAddress } from '../../../api/shipment/interfaces';
import { setIsSubmitting } from '../../Submitting/SubmittingButtonReducer';
import { HttpStatusCode } from 'axios';
import { AssociateTypes } from '../../../utils/constants';

const getSuccessMessage = (associateType: string): string => {
    return associateType === AssociateTypes.customer
        ? i18n.t('Customers:newCustomerAddedSuccessfully')
        : i18n.t('Brokers:newBrokerAddedSuccessfully');
};

export const createAssociate = createAsyncThunk(
    'associate/createAssociate',
    async (
        {
            associate,
            setFormErrorState,
            onCreateAssociate,
        }: {
            associate: IAssociate;
            setFormErrorState: (
                key: keyof AssociateFormErrorState,
                value: boolean,
            ) => void;
            onCreateAssociate?: (id: string | number) => void;
        },
        { rejectWithValue, dispatch },
    ) => {
        try {
            const associateResponse = await associateApi.createAssociate(
                associate,
            );

            if (
                associateResponse.status &&
                associateResponse.status >= HttpStatusCode.Ok &&
                associateResponse.status < HttpStatusCode.MultipleChoices
            ) {
                notifySuccess(getSuccessMessage(associate.associateType));

                onCreateAssociate && onCreateAssociate(associateResponse.data);
            } else if (
                Number(associateResponse.status) ===
                HttpStatusCode.UnprocessableEntity
            ) {
                setFormErrorState('email', true);
            }

            return associateResponse.data;
        } catch (error) {
            return rejectWithValue(error);
        } finally {
            dispatch(setIsSubmitting(false));
        }
    },
);

const initialState: IAssociateState = {
    loading: false,
    status: APIStatusOK,
    associate: NEW_ASSOCIATE_INITIAL_STATE.newAssociate,
};

const createAssociateSlice = createSlice({
    name: 'newAssociate',
    initialState,
    reducers: {
        setAssociate: (state, action: PayloadAction<IAssociate>) => {
            state.associate = action.payload;
        },
        setAssociateContacts: (state, action: PayloadAction<IContact[]>) => {
            state.associate.contacts = action.payload;
        },
        setAssociateTagGroups: (state, action: PayloadAction<ITagGroup[]>) => {
            state.associate.tagGroups = action.payload;
        },
        resetAssociate: state => {
            state.associate = NEW_ASSOCIATE_INITIAL_STATE.newAssociate;
        },
        setAssociateAttachments: (
            state,
            action: PayloadAction<IAttachment[]>,
        ) => {
            state.associate.attachments = action.payload;
        },
        setAssociateAddressState: (
            state,
            action: PayloadAction<Partial<ILocationAddress>>,
        ) => {
            if (state.associate.address && action.payload.state) {
                state.associate.address.state = action.payload.state;
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(createAssociate.pending, state => {
            state.loading = true;
            state.status = APIStatusOK;
            state.associate.associateId = undefined;
        });
        builder.addCase(createAssociate.fulfilled, (state, action) => {
            state.loading = false;
            state.associate.associateId = action.payload;
        });
        builder.addCase(createAssociate.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

export const {
    setAssociate,
    setAssociateContacts,
    setAssociateTagGroups,
    resetAssociate,
    setAssociateAttachments,
    setAssociateAddressState,
} = createAssociateSlice.actions;

export default createAssociateSlice.reducer;
