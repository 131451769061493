import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTH_INITIAL_STATE } from './initialState';
import { IUserToken } from './interface';

const authSlice = createSlice({
    name: 'auth',
    initialState: { ...AUTH_INITIAL_STATE },
    reducers: {
        setSignInData: (state, action: PayloadAction<IUserToken>) => {
            state.signInData = action.payload;
        },
        setUserEmail: (state, action: PayloadAction<string>) => {
            state.userEmail = action.payload;
        },
        setTenantId: (state, action: PayloadAction<string>) => {
            state.tenantId = action.payload;
        },
        setUserRole: (state, action: PayloadAction<string>) => {
            state.userRole = action.payload;
        },
        setPermissionList: (state, action: PayloadAction<string[]>) => {
            state.permissionList = action.payload;
        },
    },
});

export const {
    setSignInData,
    setUserEmail,
    setTenantId,
    setUserRole,
    setPermissionList,
} = authSlice.actions;

const AuthReducer = authSlice.reducer;
export default AuthReducer;
