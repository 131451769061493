import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { dashboardApi } from '../../../api/dashboard/dashboardApi';
import { IDriverCard } from '../../../api/driver/interfaces';

export const fetchHighlightedDrivers = createAsyncThunk(
    'fetchHighlightedDrivers',
    async (_, { rejectWithValue }) => {
        try {
            return await dashboardApi.fetchHighlightedDrivers();
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export interface IHighlightedDriversInitialState {
    loading: boolean;
    highlightedDrivers: IDriverCard[];
    status: APIStatus;
}

const initialState: IHighlightedDriversInitialState = {
    loading: false,
    highlightedDrivers: [],
    status: APIStatusOK,
};

export const HighlightedDriversSlice = createSlice({
    name: 'fetchHighlightedDrivers',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchHighlightedDrivers.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchHighlightedDrivers.fulfilled, (state, action) => {
            state.loading = false;
            state.highlightedDrivers = action.payload;
        });
        builder.addCase(fetchHighlightedDrivers.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

const HighlightedDriversReducer = HighlightedDriversSlice.reducer;
export default HighlightedDriversReducer;
