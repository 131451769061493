import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { NavigateFunction } from 'react-router-dom';
import { IAssociate } from '../../../api/associate/interfaces';
import { notifySuccess } from '../../../api/axios';
import associateApi from '../../../api/associate/associateApi';
import { Routes } from '../../../utils/routes';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { setIsSubmitting } from '../../../common/Submitting/SubmittingButtonReducer';
import { AssociateFormErrorState } from '../../../common/associate/interfaces';
import { HttpStatusCode } from 'axios';

export const updateCustomerDetail = createAsyncThunk(
    'updateCustomerDetails',
    async (
        {
            payload,
            associateId,
            navigate,
            setFormErrorState,
        }: {
            payload: IAssociate;
            associateId: string | number;
            navigate: NavigateFunction;
            setFormErrorState: (
                key: keyof AssociateFormErrorState,
                value: boolean,
            ) => void;
        },
        { rejectWithValue, dispatch },
    ) => {
        try {
            const response = await associateApi.updateCustomerDetail(
                associateId,
                payload,
            );

            if (
                response &&
                response >= HttpStatusCode.Ok &&
                response < HttpStatusCode.MultipleChoices
            ) {
                notifySuccess(
                    t('CustomerDetails:updatedCustomerDetailMessage'),
                );
                navigate(Routes.customers);
            } else if (
                Number(response) === HttpStatusCode.UnprocessableEntity
            ) {
                setFormErrorState('email', true);
            }
        } catch {
            return rejectWithValue('Error');
        } finally {
            dispatch(setIsSubmitting(false));
        }
    },
);

export interface IUpdateCustomerDetailInitialState {
    loading: boolean;
    status: APIStatus;
}

const initialState: IUpdateCustomerDetailInitialState = {
    loading: false,
    status: APIStatusOK,
};

export const updateCustomerDetailSlice = createSlice({
    name: 'updateCustomerDetail',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateCustomerDetail.pending, state => {
            state.loading = true;
        });
        builder.addCase(updateCustomerDetail.fulfilled, state => {
            state.loading = false;
        });
        builder.addCase(updateCustomerDetail.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

export const UpdateCustomerDetailReducer = updateCustomerDetailSlice.reducer;
export default UpdateCustomerDetailReducer;
