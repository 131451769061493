import { HttpStatusCode } from 'axios';
import { Buffer } from 'buffer';
import { TFunction } from 'i18next';
import jwtDecode from 'jwt-decode';
import { NavigateFunction } from 'react-router-dom';
import { apiCall, axiosInstanceAuth, notifyError } from '../../api/axios';
import { AppDispatch, store } from '../../redux/store';
import { ADMIN, DISPATCHER, FINANCE } from '../../utils/constants';
import { IGenericApiResponse } from '../../utils/interfaces';
import { Routes } from '../../utils/routes/constants';
import { API_CONSTANTS } from '../apiConstants';
import {
    ISaveLoginData,
    ISignInResetPasswordUserFormData,
    IUserToken,
} from './interface';
import {
    setPermissionList,
    setSignInData,
    setTenantId,
    setUserEmail,
    setUserRole,
} from './reducer';
import { ILoginUserState } from '../../pages/Login/interfaces';

export const getBasicAuthToken = (): string | null => {
    const token: string | null =
        localStorage.getItem('refreshToken') ||
        sessionStorage.getItem('refreshToken') ||
        store.getState().auth.signInData.refreshToken;

    const email: string | null =
        localStorage.getItem('email') || sessionStorage.getItem('email');

    if (!email && !token) {
        return null;
    } else {
        const parameter = email + ':' + token;
        const encoded = Buffer.from(parameter)?.toString('base64');
        return encoded;
    }
};

export const clearLocalData = (): void => {
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpiry');
    localStorage.removeItem('userRole');
    localStorage.removeItem('token');
    localStorage.removeItem('actionPermissions');
    localStorage.removeItem('tenantId');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('refreshTokenExpiry');
    sessionStorage.removeItem('userRole');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('tenantId');
    sessionStorage.removeItem('actionPermissions');
};

export const signInUser =
    (user: ILoginUserState, navigate: NavigateFunction, t: TFunction) =>
    async (dispatch: AppDispatch) => {
        const { email, password, rememberMe } = user;
        const parameter = email + ':' + password;
        const encoded = Buffer.from(parameter)?.toString('base64');
        const response: IGenericApiResponse<IUserToken> = await apiCall(
            'get',
            `${API_CONSTANTS.auth}`,
            {
                headers: {
                    Authorization: 'Basic ' + encoded,
                },
            },
            true,
        );

        if (!response || response.status !== HttpStatusCode.Ok) {
            return;
        }

        const loginDetail = response.data;
        const userId =
            loginDetail.token && jwtDecode<string>(loginDetail.token);
        clearLocalData();
        const permissionList = Object.values(loginDetail.actionPermissions);

        if (
            !(
                loginDetail.roles.includes(ADMIN) ||
                loginDetail.roles.includes(DISPATCHER) ||
                loginDetail.roles.includes(FINANCE)
            )
        ) {
            notifyError(t('Common:unauthorized'));
            return;
        }

        if (loginDetail.refreshToken && loginDetail.refreshTokenExpiry) {
            saveLoginData({
                local: rememberMe,
                refreshToken: loginDetail.refreshToken,
                refreshTokenExpiry: loginDetail.refreshTokenExpiry?.toString(),
                token: loginDetail.token,
                userRole: loginDetail.roles,
                userId: userId,
                email: email,
                tenantId: loginDetail.tenantId,
                actionPermissions: loginDetail.actionPermissions,
            });
        }

        dispatch(setSignInData(loginDetail));
        dispatch(setTenantId(loginDetail.tenantId));
        dispatch(setUserRole(loginDetail.roles[0]));
        dispatch(setUserEmail(email));
        dispatch(setPermissionList(permissionList));

        navigate(Routes.dashboard);
    };

export const canSignInResetPasswordUser =
    (formData: ISignInResetPasswordUserFormData) =>
    async (dispatch: AppDispatch): Promise<boolean> => {
        const { email, resetCode } = formData;
        const parameter = email + ':' + resetCode;
        const encoded = Buffer?.from(parameter)?.toString('base64');
        const response: IGenericApiResponse<IUserToken> = await apiCall(
            'get',
            `${API_CONSTANTS.auth}${API_CONSTANTS.transient}`,
            {
                headers: {
                    Authorization: 'Basic ' + encoded,
                },
            },
            true,
        );

        if (
            response.status &&
            response.status === HttpStatusCode.Unauthorized
        ) {
            return false;
        } else {
            dispatch(setSignInData(response.data));
            return true;
        }
    };

export const getRefreshToken = async (): Promise<void> => {
    try {
        const res: IGenericApiResponse<IUserToken> = await axiosInstanceAuth[
            'get'
        ](`${API_CONSTANTS.auth}${API_CONSTANTS.refresh}`, {});

        if (res.data.refreshToken && res.data.refreshTokenExpiry) {
            const isRememberMe = !!localStorage.getItem('refreshToken');
            saveLoginData({
                local: isRememberMe,
                refreshToken: res.data.refreshToken,
                refreshTokenExpiry: res.data.refreshTokenExpiry,
                token: res.data.token,
                userRole: res.data.roles,
                tenantId: res.data.tenantId,
                actionPermissions: res.data.actionPermissions,
            });
        }

        window.location.reload();
    } catch (error) {
        console.error(error);
        clearLocalData();
        window.location.replace(window.location.origin + Routes.login);
    }
};

export const rememberMeLogin = async (
    navigate: NavigateFunction,
): Promise<void> => {
    try {
        const res: IGenericApiResponse<IUserToken> = await axiosInstanceAuth[
            'get'
        ](`${API_CONSTANTS.auth}${API_CONSTANTS.refresh}`, {});

        if (res.status === HttpStatusCode.Ok) {
            const isRememberMe = !!localStorage.getItem('refreshToken');
            if (isRememberMe) {
                saveLoginData({
                    local: isRememberMe,
                    refreshToken: res.data.refreshToken,
                    refreshTokenExpiry: res.data.refreshTokenExpiry,
                    token: res.data.token,
                    userRole: res.data.roles,
                    tenantId: res.data.tenantId,
                    actionPermissions: res.data.actionPermissions,
                });
            }

            navigate(Routes.dashboard);
        }
    } catch (error) {
        console.error(error);
    }
};

const saveLoginData = ({
    local,
    refreshToken,
    refreshTokenExpiry,
    token,
    userRole,
    userId,
    email,
    tenantId,
    actionPermissions,
}: ISaveLoginData): void => {
    const storageType: Storage = local ? localStorage : sessionStorage;
    userId && storageType.setItem('userId', userId);
    email && storageType.setItem('email', email);
    storageType.setItem('refreshToken', refreshToken);
    storageType.setItem('refreshTokenExpiry', refreshTokenExpiry);
    userRole && storageType.setItem('userRole', JSON.stringify(userRole));
    storageType.setItem('token', token);
    tenantId && storageType.setItem('tenantId', tenantId);
    actionPermissions &&
        storageType.setItem(
            'actionPermissions',
            JSON.stringify(actionPermissions),
        );
};

export const isUserLoggedIn = (): boolean => {
    return getBasicAuthToken() !== null;
};

export const signOutUser = (navigate?: NavigateFunction | undefined): void => {
    if (navigate) {
        clearLocalData();
        navigate(Routes.login);
        window.location.reload();
    }
};
