import { apiCall } from '../axios';
import { API_CONSTANTS } from '../../services/apiConstants';
import { ITrailerDetail } from './interface/ITrailerDetail';
import { ITrailerDetailResponse } from './interface/ITrailerDetailResponse';

const fetchTrailerList = async (
    top?: number,
    skip?: number,
): Promise<ITrailerDetailResponse[]> => {
    let url = `${API_CONSTANTS.trailer}/odata?include=DriverTrailers,DriverTrailers.Driver&filter=isDeleted eq false&$orderby=TrailerNumber`;
    if (top !== undefined) {
        url += `&$top=${top}`;
    }

    if (skip !== undefined) {
        url += `&$skip=${skip}`;
    }
    const response = await apiCall('get', url, {}, true);
    return response.data;
};

const addTrailer = async (payload: ITrailerDetail): Promise<number> => {
    const response = await apiCall(
        'post',
        `${API_CONSTANTS.trailer}`,
        payload,
        true,
    );

    return response.status;
};

const updateTrailer = async (
    trailerId: number,
    payload: ITrailerDetail,
): Promise<number> => {
    const response = await apiCall(
        'put',
        `${API_CONSTANTS.trailer}/${trailerId}`,
        payload,
        true,
    );

    return response.status;
};

const updateMultipleTrailer = async (
    payload: {
        driverId: number | null;
        trailerId: number;
    }[],
): Promise<number> => {
    const response = await apiCall(
        'post',
        `${API_CONSTANTS.trailer}${API_CONSTANTS.reassigntrailer}`,
        payload,
        true,
    );

    return response.status;
};

const fetchTrailerDetail = async (
    trailerId: number | string,
): Promise<ITrailerDetailResponse> => {
    const response = await apiCall(
        'get',
        `${API_CONSTANTS.trailer}/${trailerId}?include=DriverTrailers%2CDriverTrailers.Driver`,
        {},
        true,
    );

    return response.data;
};

export const deleteTrailer = async (trailerId: number): Promise<number> => {
    const response = await apiCall(
        'delete',
        `${API_CONSTANTS.trailer}/${trailerId}`,
        {},
        true,
    );

    return response.status;
};

export const trailerApi = {
    addTrailer,
    updateTrailer,
    fetchTrailerList,
    fetchTrailerDetail,
    deleteTrailer,
    updateMultipleTrailer,
};
