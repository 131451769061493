export const DeclinedLoadsHeader = [
    {
        name: 'FullActivityLog:driverName',
    },
    {
        name: 'FullActivityLog:date/time',
    },
    {
        name: 'FullActivityLog:customerName',
    },
    {
        name: 'FullActivityLog:pickUpLocation',
    },
    {
        name: 'FullActivityLog:dropOffLocation',
    },
    {
        name: '',
    },
];

export const DeclinedLoadDummyData = [
    {
        driverName: 'John Doe',
        date: '2024-11-20 08:30 AM',
        customerName: 'Acme Corp',
        pickUpLocation: 'Warehouse A, New York, NY',
        dropOffLocation: 'Distribution Center, Boston, MA',
    },
    {
        driverName: 'Jane Smith',
        date: '2024-11-20 12:15 PM',
        customerName: 'Global Industries',
        pickUpLocation: 'Factory 1, Chicago, IL',
        dropOffLocation: 'Retail Store, Indianapolis, IN',
    },
    {
        driverName: 'Carlos Martinez',
        date: '2024-11-20 02:45 PM',
        customerName: 'Green Valley Organics',
        pickUpLocation: 'Farmhouse, Austin, TX',
        dropOffLocation: 'Processing Plant, Dallas, TX',
    },
    {
        driverName: 'Aisha Khan',
        date: '2024-11-20 10:00 AM',
        customerName: 'Blue Sky Retailers',
        pickUpLocation: 'Warehouse B, Los Angeles, CA',
        dropOffLocation: 'Shopping Mall, San Diego, CA',
    },
    {
        driverName: 'Liam Brown',
        date: '2024-11-20 05:00 PM',
        customerName: 'Sunrise Logistics',
        pickUpLocation: 'Hub 3, Miami, FL',
        dropOffLocation: 'Port, Tampa, FL',
    },
];
