import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { COUNTRY_CODE_LIST } from '../initailState';
import { ISelect } from '../../../utils/interfaces';

export const countryCode = createSlice({
    name: 'countryCode',
    initialState: COUNTRY_CODE_LIST,
    reducers: {
        setCountryCodeOptions: (state, action: PayloadAction<ISelect[]>) => {
            state.countryCodeOptions = action.payload;
        },
    },
});

export const { setCountryCodeOptions } = countryCode.actions;

const CountryCodeReducer = countryCode.reducer;
export default CountryCodeReducer;
