import { INITIAL_LAT, INITIAL_LNG, INITIAL_ZOOM } from './constants';
import { IMapsInitialState } from './interface';

export const MAPS_INITIAL_STATE: IMapsInitialState = {
    mapDetails: {
        location: {
            lat: 0,
            lng: 0,
        },
        markers: [
            {
                position: {
                    lat: INITIAL_LAT,
                    lng: INITIAL_LNG,
                },
                key: '',
            },
        ],
        zoom: INITIAL_ZOOM,
        center: {
            lat: INITIAL_LAT,
            lng: INITIAL_LNG,
        },
    },
};
