import { IDriverCard } from '../api/driver/interfaces';
import { AttachmentExpiryTypes } from './constants';
import { encodeSpecialCharacters } from './functions';

interface IDriverUtils {
    formatDriverList(driverList: IDriverCard[]): IDriverCard[];
    driverListFilterQueryString: (
        currentLocation?: string,
        drierStatus?: boolean,
        driverName?: string,
    ) => string;
}

export class DriverUtils implements IDriverUtils {
    formatDriverList(driverList: IDriverCard[]): IDriverCard[] {
        return driverList.map((driverDetail: IDriverCard) => {
            const { attachments, name } = driverDetail;

            const documentExpired = attachments
                ?.filter(
                    attachment =>
                        attachment.documentExpiryStatus ===
                        AttachmentExpiryTypes.expired,
                )
                .map(attachment => ({
                    ...attachment,
                    driverName: name,
                }));

            const documentExpiringSoon = attachments
                ?.filter(
                    attachment =>
                        attachment.documentExpiryStatus ===
                        AttachmentExpiryTypes.expiringSoon,
                )
                .map(attachment => ({
                    ...attachment,
                    driverName: name,
                }));

            return {
                ...driverDetail,
                expired: documentExpired || [],
                expiringSoon: documentExpiringSoon || [],
            };
        });
    }

    driverListFilterQueryString = (
        currentLocation?: string,
        driverStatus?: boolean,
        driverName?: string,
    ): string => {
        let filterQuery = `(Discriminator eq 'Driver' or Discriminator eq 'Broker')`;

        if (driverName) {
            filterQuery += `and (contains(name, '${encodeSpecialCharacters(
                driverName,
            )}'))`;
        }

        if (driverStatus !== undefined) {
            filterQuery += `and (isActive eq ${driverStatus})`;
        }

        if (currentLocation) {
            filterQuery += `and (tagGroups/any(tagGroup: contains(tagGroup/name, '${currentLocation}')))`;
        }

        if (filterQuery) {
            filterQuery = `&$filter=${filterQuery}`;
        }

        return filterQuery;
    };
}
