export const BreakdownHeader = [
    {
        name: 'FullActivityLog:driverName',
    },
    {
        name: 'FullActivityLog:date/time',
    },
    {
        name: 'FullActivityLog:orderNumber',
    },
    {
        name: 'FullActivityLog:pinDrop',
    },
    {
        name: 'FullActivityLog:customerName',
    },
    {
        name: '',
    },
];

export const BreakdownDummyData = [
    {
        driverName: 'John Doe',
        date: '2024-11-20 09:00 AM',
        orderNumber: 'ORD123456',
        customerName: 'Acme Corp',
    },
    {
        driverName: 'Jane Smith',
        date: '2024-11-20 01:45 PM',
        orderNumber: 'ORD789012',
        customerName: 'Global Industries',
    },
    {
        driverName: 'Carlos Martinez',
        date: '2024-11-20 03:30 PM',
        orderNumber: 'ORD345678',
        customerName: 'Green Valley Organics',
    },
    {
        driverName: 'Aisha Khan',
        date: '2024-11-20 11:15 AM',
        orderNumber: 'ORD901234',
        customerName: 'Blue Sky Retailers',
    },
    {
        driverName: 'Liam Brown',
        date: '2024-11-20 04:00 PM',
        orderNumber: 'ORD567890',
        customerName: 'Sunrise Logistics',
    },
];
