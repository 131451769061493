import { IContact } from '../../api/associate/interfaces';
import { InvoiceType, PriorityType } from '../../components/Associate/enum';
import { AssociateTypes } from '../../utils/constants';
import {
    IAssociateDetailInitialState,
    IAssociateInitialState,
} from './interfaces';

export const CONTACT_INITIAL_STATE: IContact = {
    contactId: 0,
    isPrimary: false,
    name: '',
    title: '',
    phoneNumber: '',
    ext: '',
    email: '',
};

export const NEW_ASSOCIATE_INITIAL_STATE: IAssociateInitialState = {
    newAssociate: {
        name: '',
        phoneNumber: '',
        ext: '',
        faxNumber: '',
        email: null,
        notes: '',
        address: {
            locationId: 0,
            type: 'Home',
            streetAddress: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            latitude: 0,
            longitude: 0,
            instructions: '',
        },
        isActive: true,
        associateType: AssociateTypes.customer,
        invoiceType: InvoiceType.brokenOut,
        autoShrinkPercentage: null,
        priority: PriorityType.normalPriority,
        csaRatingPercentage: null,
        unitNumber: null,
        trailerNumber: null,
        truckingCoNumber: null,
        contacts: [CONTACT_INITIAL_STATE],
        attachments: [],
        brokerFeeType: 'Percentage',
        brokerFeeAmount: '',
        isBrokenDown: false,
    },
};

export const ASSOCIATE_DETAIL_INITIAL_STATE: IAssociateDetailInitialState = {
    loading: false,
    associateDetail: NEW_ASSOCIATE_INITIAL_STATE.newAssociate,
    selectedAssociateState: {
        value: '',
        label: '',
    },
    selectedAssociateDetail: NEW_ASSOCIATE_INITIAL_STATE.newAssociate,
    updatedAssociateDetail: NEW_ASSOCIATE_INITIAL_STATE.newAssociate,
    sourceRoute: '',
};
