import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import driverLoadApi from '../../../../../api/driverLoads/driverLoadsApi';
import { IDriverLoad } from '../../../../../api/loadBoard/interfaces';
import { IDriverLoadInitialState } from '../interfaces/IDriverLoadInitialState';
import { setIsSubmitting } from '../../../../../common/Submitting/SubmittingButtonReducer';

export const createDriverLoad = createAsyncThunk(
    'createDriverLoad',
    async (
        {
            shipmentId,
            loads,
        }: {
            shipmentId: number | string;
            loads: IDriverLoad;
        },
        { rejectWithValue, dispatch },
    ) => {
        try {
            const response: number[] = await driverLoadApi.createDriverLoad(
                shipmentId,
                loads,
            );

            return response[0];
        } catch {
            return rejectWithValue('Error');
        } finally {
            dispatch(setIsSubmitting(false));
        }
    },
);

const initialState: IDriverLoadInitialState = {
    loading: false,
    status: HttpStatusCode.NoContent,
    driverLoadId: null,
};

export const driverLoadSlice = createSlice({
    name: 'createDriverLoad',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createDriverLoad.pending, state => {
            state.loading = true;
        });
        builder.addCase(createDriverLoad.fulfilled, (state, action) => {
            state.loading = false;
            state.status = HttpStatusCode.Ok;
            state.driverLoadId = action.payload;
        });
        builder.addCase(createDriverLoad.rejected, state => {
            state.loading = false;
        });
    },
});

const createDriverLoadsReducer = driverLoadSlice.reducer;
export default createDriverLoadsReducer;
