import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIStatus, APIStatusOK } from '../../../api/apiError';
import { dateFormatter } from '../../../utils/functions';
import { NullableDate } from '../../../utils/interfaces';
import { reportApi } from '../../../api/report/reportApi';

export const fetchTrailerIncomeReport = createAsyncThunk(
    'fetchTrailerIncomeReport',
    async (
        {
            startDate,
            endDate,
        }: { startDate: NullableDate; endDate: NullableDate },
        { rejectWithValue },
    ) => {
        try {
            const formattedStartDate = dateFormatter(startDate || '');
            const formattedEndDate = dateFormatter(endDate || '');

            const response = await reportApi.fetchTrailerIncomeReport({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            });

            return response;
        } catch {
            return rejectWithValue('error');
        }
    },
);

interface ITrailerIncomeReportInitialState {
    loading: boolean;
    trailerIncomeReportDetail: Blob | null;
    status: APIStatus;
}

const initialState: ITrailerIncomeReportInitialState = {
    loading: false,
    trailerIncomeReportDetail: null,
    status: APIStatusOK,
};

export const fetchTrailerIncomeReportSlice = createSlice({
    name: 'fetchTrailerIncomeReport',
    initialState,
    reducers: {
        setTrailerIncomeReportDetail: (
            state,
            action: PayloadAction<Blob | null>,
        ) => {
            state.trailerIncomeReportDetail = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchTrailerIncomeReport.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchTrailerIncomeReport.fulfilled, (state, action) => {
            state.loading = false;
            state.trailerIncomeReportDetail = action.payload;
        });
        builder.addCase(fetchTrailerIncomeReport.rejected, (state, action) => {
            state.loading = false;
            state.status = action.payload as APIStatus;
        });
    },
});

export const { setTrailerIncomeReportDetail } =
    fetchTrailerIncomeReportSlice.actions;

const TrailerIncomeReportReducer = fetchTrailerIncomeReportSlice.reducer;
export default TrailerIncomeReportReducer;
