import { API_CONSTANTS } from '../../services/apiConstants';
import { IGenericApiResponse } from '../../utils/interfaces';
import { apiCall } from '../axios';
import { ITagGroup } from '../interfaces';

export const fetchTagGroups = async (): Promise<ITagGroup[]> => {
    const response: IGenericApiResponse<ITagGroup[]> = await apiCall(
        'get',
        `${API_CONSTANTS.taggroups}?sort=Name`,
        {},
        true,
    );

    return response.data;
};

export const tagGroupApi = {
    fetchTagGroups,
};
