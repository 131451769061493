import { IShipmentLoadBoardViewItem } from '../../../api/shipment/interfaces';

export const getTagGroupId = (
    shipmentDetail: IShipmentLoadBoardViewItem,
): string => {
    const tagGroupId = shipmentDetail.shipmentLocations.find(
        shipmentLocation => +shipmentLocation.ordinal === 0,
    )?.location.tagGroup?.tagGroupId;

    if (tagGroupId) {
        return `and (TagGroups/any(t:t/TagGroupId eq ${tagGroupId})`;
    }

    return '';
};
