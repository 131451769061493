import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LocalizationLanguages } from './constants';
import './style.css';

export const LocaleDropdown: FC = () => {
    const { i18n } = useTranslation();

    const handleLocalization = (languageCode: string): void => {
        i18n.changeLanguage(languageCode);
    };

    return (
        <Dropdown>
            <Dropdown.Toggle className="p-0 localization-dropdown-toggle">
                <FontAwesomeIcon icon={faGlobe} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
                {LocalizationLanguages.map((language, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={() => handleLocalization(language.code)}
                    >
                        {language.name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
