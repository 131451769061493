import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import associateApi from '../../../api/associate/associateApi';
import { IDriverCard } from '../../../api/driver/interfaces';
import { IShipmentLoadBoardViewItem } from '../../../api/shipment/interfaces';
import { DriverUtils } from '../../../utils/DriverUtils';
import { getTagGroupId } from '../functions/getTagGroupId';
import { getTrailerSize } from '../functions/getTrailerSize';
import { ISuggestedDriversInitialState } from '../interfaces/ISuggestedDrivers';

export const fetchSuggestedDrivers = createAsyncThunk(
    'fetchSuggestedDrivers',
    async (
        { shipmentDetail }: { shipmentDetail: IShipmentLoadBoardViewItem },
        { rejectWithValue },
    ) => {
        const tagGroupId = getTagGroupId(shipmentDetail);
        const trailerSize = getTrailerSize(shipmentDetail, false);
        const driverUtils = new DriverUtils();

        try {
            const response = await associateApi.fetchSuggestedDrivers(
                tagGroupId,
                trailerSize,
            );

            return driverUtils.formatDriverList(response.data);
        } catch {
            return rejectWithValue('Error');
        }
    },
);

const initialState: ISuggestedDriversInitialState = {
    loading: false,
    suggestedDrivers: [],
    filteredSuggestedDrivers: [],
    suggestedDriversAssociateId: [],
};

export const suggestedDriversSlice = createSlice({
    name: 'fetchSuggestedDrivers',
    initialState,
    reducers: {
        setSuggestedDrivers: (state, action: PayloadAction<IDriverCard[]>) => {
            state.suggestedDrivers = action.payload;
        },
        setFilteredSuggestedDrivers: (
            state,
            action: PayloadAction<IDriverCard[]>,
        ) => {
            state.filteredSuggestedDrivers = action.payload;
        },
        removeSelectedSuggestedDriver: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.filteredSuggestedDrivers =
                state.filteredSuggestedDrivers.filter(
                    driver => driver.associateId !== action.payload,
                );
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSuggestedDrivers.pending, state => {
            state.loading = true;
            state.suggestedDrivers = [];
        });
        builder.addCase(fetchSuggestedDrivers.fulfilled, (state, action) => {
            state.loading = false;
            state.suggestedDrivers = action.payload;
            state.suggestedDriversAssociateId = action.payload.map(
                driverDetail => driverDetail.associateId,
            );
        });
        builder.addCase(fetchSuggestedDrivers.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export const {
    setSuggestedDrivers,
    setFilteredSuggestedDrivers,
    removeSelectedSuggestedDriver,
} = suggestedDriversSlice.actions;

const SuggestedDriversReducer = suggestedDriversSlice.reducer;
export default SuggestedDriversReducer;
