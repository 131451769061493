import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const locationModal = createSlice({
    name: 'locationModal',
    initialState: { hasUnsavedChanges: false, showLocationModal: false },
    reducers: {
        setShowLocationModal: (state, action: PayloadAction<boolean>) => {
            state.showLocationModal = action.payload;
        },
    },
});

export const { setShowLocationModal } = locationModal.actions;

const LocationModalReducer = locationModal.reducer;
export default LocationModalReducer;
