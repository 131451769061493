import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    ICommunicationDetails,
    ICompanyDetails,
    IInvoicingDetails,
} from '../interfaces/ISettingsDetails';
import { ISetting } from '../../../api/settings/interface/ISetting';

interface ISettingsDetailInitialState {
    companyDetails: ICompanyDetails;
    communicationDetails: ICommunicationDetails;
    settingsList: ISetting[];
    invoicingDetails: IInvoicingDetails;
    canEditSettings: boolean;
}

const initialState: ISettingsDetailInitialState = {
    companyDetails: {
        companyName: '',
        companyAddress: '',
        phoneNumber: '',
        dispatchSupportNumber: '',
        emailAddress: '',
        companyLogo: '',
        brokerFeeType: '',
        brokerFeeAmount: '',
        faxNumber: '',
        city: '',
        state: '',
        zipCode: '',
        baseSettingId: 0,
        settlementReportBatchNumber: '',
    },
    communicationDetails: {
        isGreetingMessageIncluded: false,
        greetingMessage: '',
        isOrderNumberIncluded: false,
        isDateIncluded: false,
        isTimeFormat12Hr: false,
        isPinDropNameIncluded: false,
        isLocationInstructionIncluded: false,
        isEstimatedWeightIncluded: false,
        isEstimatedHeadCountIncluded: false,
        isCommodityIncluded: false,
        isDriverInstructionIncluded: false,
        isEndingMessageIncluded: false,
        endingMessage: '',
    },
    settingsList: [],
    invoicingDetails: {
        isSendInvoices: false,
        invoiceRecipients: [],
        isConnectToQuickBooks: true,
    },
    canEditSettings: false,
};

export const settingsDetail = createSlice({
    name: 'settingsDetail',
    initialState: initialState,
    reducers: {
        setCompanyDetails: (state, action: PayloadAction<ICompanyDetails>) => {
            state.companyDetails = action.payload;
        },
        setCommunicationDetails: (
            state,
            action: PayloadAction<ICommunicationDetails>,
        ) => {
            state.communicationDetails = action.payload;
        },
        setSettingsList: (state, action: PayloadAction<ISetting[]>) => {
            state.settingsList = action.payload;
        },
        setInvoicingDetails: (
            state,
            action: PayloadAction<IInvoicingDetails>,
        ) => {
            state.invoicingDetails = action.payload;
        },
        setCanEditSettings: (state, action: PayloadAction<boolean>) => {
            state.canEditSettings = action.payload;
        },
    },
});

export const {
    setCompanyDetails,
    setSettingsList,
    setCommunicationDetails,
    setInvoicingDetails,
    setCanEditSettings,
} = settingsDetail.actions;

const settingsReducer = settingsDetail.reducer;
export default settingsReducer;
