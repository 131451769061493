export const FilePickerExtensions = {
    documentUpload: ['jpg', 'jpeg', 'png', 'pdf', 'heic', 'heif'],
    profileImage: ['jpg', 'jpeg', 'png'],
    maxSize: 25,
    documentUploadSupportTicket: [
        'jpeg',
        'png',
        'pdf',
        'svg',
        'heif',
        'mov',
        'mp4',
    ],
    DocumentUploadSupportTicketMaxSize: 50,
};
