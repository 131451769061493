import { API_CONSTANTS } from '../../services/apiConstants';
import { IGenericApiResponse } from '../../utils/interfaces';
import { IAssociatePinDrop } from '../associate/interfaces';
import { apiCall } from '../axios';
import { IPindrop } from '../shipment/interfaces';

const fetchPinDrops = async (): Promise<IPindrop[]> => {
    const response: IGenericApiResponse<IPindrop[]> = await apiCall(
        'get',
        `${API_CONSTANTS.pindrops}?include=location&sort=name`,
        {},
        true,
    );

    return response.data;
};

const createPinDrop = async (
    pindropDetails: Partial<IPindrop>,
): Promise<number> => {
    const response: IGenericApiResponse<number> = await apiCall(
        'post',
        `${API_CONSTANTS.pindrops}`,
        pindropDetails,
        true,
    );

    return response.data;
};

const deletePinDrop = async (
    pinDropId: number | string,
    associateId: number | string,
): Promise<void> => {
    const response = await apiCall(
        'delete',
        `${API_CONSTANTS.pindrops}/${pinDropId}${API_CONSTANTS.associates}/${associateId}`,
        {},
        true,
    );

    return response;
};

const fetchPinDropDetail = async (
    pinDropId: number | string,
): Promise<IAssociatePinDrop> => {
    const response: IGenericApiResponse<IAssociatePinDrop> = await apiCall(
        'get',
        `${API_CONSTANTS.pindrops}/${pinDropId}?include=Location`,
        {},
        true,
    );

    return response.data;
};

const updatePinDropDetail = async (
    pinDropId: string | number,
    payload: Partial<IPindrop>,
): Promise<void> => {
    await apiCall(
        'put',
        `${API_CONSTANTS.pindrops}/${pinDropId}`,
        payload,
        true,
    );
};

const fetchPinDropByAssociateId = async (
    associateId: number,
): Promise<IAssociatePinDrop[]> => {
    const response: IGenericApiResponse<IAssociatePinDrop[]> = await apiCall(
        'get',
        `${API_CONSTANTS.pindrops}${API_CONSTANTS.associate}/${associateId}?sort=name`,
        {},
        true,
    );

    return response.data;
};

export const pinDropApi = {
    createPinDrop,
    deletePinDrop,
    fetchPinDrops,
    fetchPinDropDetail,
    updatePinDropDetail,
    fetchPinDropByAssociateId,
};
