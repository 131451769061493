export const dashboardReportHeaders = [
    { label: 'Month', key: 'month' },
    { label: 'My Loads', key: 'myLoads' },
    { label: 'Broker Loads', key: 'brokerLoads' },
    { label: 'Total Loads', key: 'totalLoads' },
];

export const csvFileName = 'monthly_load_count.csv';
export const pdfFileName = 'monthly_load_count.pdf';
export const CSV = 'csv';
export const PDF = 'pdf';
