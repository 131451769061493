import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAttachment } from '../../api/interfaces';
import { IDriverLoad } from '../../api/loadBoard/interfaces';
import { IShipmentLoadBoardViewItem } from '../../api/shipment/interfaces';
import { shipmentsApi } from '../../api/shipment/shipmentsApi';
import { FilterLimit } from '../../utils/constants';
import { ISelect, ISingleValueSelect } from '../../utils/interfaces';
import { ILoadBoardSearchFilters, ILoadBoardStatusFilters } from './interfaces';
import { ILoadBoardCanvas } from './interfaces/ILoadBoardCanvas';
import { ISelectedDriverIdList } from './interfaces/ISelectedDriverList';
import { LOAD_BOARD_INITIAL_STATE } from './intialState';

export const fetchShipmentLoadBoardViewItems = createAsyncThunk(
    'fetchShipmentLoadBoardViewItems',
    async (
        {
            loadBoardSearchFilters,
            loadBoardStatusFilters,
        }: {
            loadBoardSearchFilters: ILoadBoardSearchFilters;
            loadBoardStatusFilters: ILoadBoardStatusFilters;
        },
        { rejectWithValue },
    ) => {
        try {
            const response = await shipmentsApi.fetchShipmentDetails(
                loadBoardSearchFilters,
                loadBoardStatusFilters,
            );
            return response;
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export const loadBoardSlice = createSlice({
    name: 'loadBoard',
    initialState: LOAD_BOARD_INITIAL_STATE,
    reducers: {
        setLoadBoardLoading: (state, action: PayloadAction<boolean>) => {
            state.loadBoardLoading = action.payload;
        },
        setLoadBoardSearchFilters: (
            state,
            action: PayloadAction<Partial<ILoadBoardSearchFilters>>,
        ) => {
            state.loadBoardSearchFilters = {
                ...state.loadBoardSearchFilters,
                ...action.payload,
            };
        },
        setLoadBoardStatusFilters: (
            state,
            action: PayloadAction<Partial<ILoadBoardStatusFilters>>,
        ) => {
            state.loadBoardStatusFilters = {
                ...state.loadBoardStatusFilters,
                ...action.payload,
            };
        },
        updateShipmentLoadBoardViewItem: (
            state,
            action: PayloadAction<IShipmentLoadBoardViewItem>,
        ) => {
            state.shipmentDetails.forEach(
                (data: IShipmentLoadBoardViewItem, index: number) => {
                    if (action.payload.shipmentId === data.shipmentId) {
                        state.shipmentDetails[index] = action.payload;
                    }
                },
            );
            state.selectedShipmentDetail = action.payload;
        },
        setCurrentShipmentDetail: (
            state,
            action: PayloadAction<IShipmentLoadBoardViewItem>,
        ) => {
            state.currentShipmentDetail = action.payload;
        },
        setSelectedShipmentdetail: (
            state,
            action: PayloadAction<IShipmentLoadBoardViewItem>,
        ) => {
            state.selectedShipmentDetail = action.payload;
        },
        setShipmentDetails: (
            state,
            action: PayloadAction<IShipmentLoadBoardViewItem[]>,
        ) => {
            state.shipmentDetails = action.payload;
        },
        setShowShipmentActionsPopover: (
            state,
            action: PayloadAction<boolean[]>,
        ) => {
            state.showShipmentActionsPopover = action.payload;
        },
        setDriverLoads: (state, action: PayloadAction<IDriverLoad[]>) => {
            state.driverLoads = action.payload;
        },
        setShowDriverLoadModal: (state, action: PayloadAction<boolean>) => {
            state.showDriverLoadModal = action.payload;
        },
        setShowAttachBillOfLadingModal: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showAttachBillOfLadingModal = action.payload;
        },
        setIsAddDriverLoad: (state, action: PayloadAction<boolean>) => {
            state.isAddDriverLoad = action.payload;
        },
        setShowDriverReVerifyRateModal: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showDriverReVerifyRateModal = action.payload;
        },
        setReVerifyDriverCommodityTypes: (
            state,
            action: PayloadAction<{
                index: number;
                commodityType: string;
            }>,
        ) => {
            const { index, commodityType } = action.payload;
            state.reVerifyDriverCommodityTypes[index] = commodityType;
        },
        setSelectedDriverLoad: (
            state,
            action: PayloadAction<{
                driverLoadId: number | null;
                driverLoadStatus: string | null;
            }>,
        ) => {
            state.selectedDriverLoad = action.payload.driverLoadId;
            state.selectedDriverLoadStatus = action.payload.driverLoadStatus;
        },
        setDriverLoad: (state, action: PayloadAction<IDriverLoad>) => {
            state.driverLoad = action.payload;
        },
        setDriverLoadAttachments: (
            state,
            action: PayloadAction<IAttachment[]>,
        ) => {
            state.driverLoad.attachments = action.payload;
        },
        setSelectedDriverIdList: (
            state,
            action: PayloadAction<ISelectedDriverIdList[]>,
        ) => {
            state.selectedDriverIdList = action.payload;
        },
        setSelectedAdditionalCharges: (
            state,
            action: PayloadAction<string[]>,
        ) => {
            state.selectedAdditionalCharges = action.payload;
        },
        setSelectedDeductionCharges: (
            state,
            action: PayloadAction<string[]>,
        ) => {
            state.selectedDeductionCharges = action.payload;
        },
        setShowDeleteShipmentModal: (state, action: PayloadAction<boolean>) => {
            state.showDeleteShipmentModal = action.payload;
        },
        setUpdatedShipmentDetail: (
            state,
            action: PayloadAction<IShipmentLoadBoardViewItem>,
        ) => {
            state.updatedShipmentDetail = action.payload;
        },
        setDriverListActiveKey: (
            state,
            action: PayloadAction<string | null>,
        ) => {
            state.driverListActiveKey = action.payload;
        },
        setCanFetchDriverList: (state, action: PayloadAction<boolean>) => {
            state.canFetchDriverList = action.payload;
        },
        setIsShipmentStatusChanged: (state, action: PayloadAction<boolean>) => {
            state.isShipmentStatusChanged = action.payload;
        },
        setCanCallShipmentListApi: (state, action: PayloadAction<boolean>) => {
            state.canCallShipmentListApi = action.payload;
        },
        setSelectedCustomerName: (
            state,
            action: PayloadAction<ISingleValueSelect>,
        ) => {
            state.selectedCustomerName = action.payload;
        },
        setSelectedDriverName: (
            state,
            action: PayloadAction<ISingleValueSelect>,
        ) => {
            state.selectedDriverName = action.payload;
        },
        resetLoadBoardShipmentDetails: state => {
            state.shipmentDetails = [];
            state.loadBoardSearchFilters =
                LOAD_BOARD_INITIAL_STATE.loadBoardSearchFilters;
            state.loadBoardStatusFilters =
                LOAD_BOARD_INITIAL_STATE.loadBoardStatusFilters;
            state.showLoadBoardCanvas =
                LOAD_BOARD_INITIAL_STATE.showLoadBoardCanvas;
            state.selectedCustomerName =
                LOAD_BOARD_INITIAL_STATE.selectedCustomerName;
            state.selectedDriverName =
                LOAD_BOARD_INITIAL_STATE.selectedDriverName;
        },
        setShowLoadBoardCanvas: (
            state,
            action: PayloadAction<ILoadBoardCanvas>,
        ) => {
            state.showLoadBoardCanvas = action.payload;
        },
        setAssociatedDriverList: (state, action: PayloadAction<ISelect[]>) => {
            state.associatedDriverList = action.payload;
        },
        setShowOwnerOperator: (state, action: PayloadAction<boolean>) => {
            state.showOwnerOperator = action.payload;
        },
        setDeductionChargeCommodityList: (
            state,
            action: PayloadAction<ISelect[]>,
        ) => {
            state.deductionChargeCommodityList = action.payload;
        },
        setSelectedDriverCommodity: (
            state,
            action: PayloadAction<ISelect[] | null>,
        ) => {
            state.selectedDriverCommodity = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchShipmentLoadBoardViewItems.pending, state => {
            state.loadBoardLoading = true;
        });
        builder.addCase(
            fetchShipmentLoadBoardViewItems.fulfilled,
            (state, action) => {
                state.loadBoardLoading = false;
                state.shipmentDetails = action.payload;
                state.canCallShipmentListApi = state.loadBoardStatusFilters
                    .offset
                    ? action.payload.length >=
                      state.loadBoardStatusFilters.offset + FilterLimit
                    : true;
            },
        );
        builder.addCase(
            fetchShipmentLoadBoardViewItems.rejected,
            (state, action) => {
                state.loadBoardLoading = false;
            },
        );
    },
});

export const {
    setLoadBoardLoading,
    setLoadBoardSearchFilters,
    setLoadBoardStatusFilters,
    setUpdatedShipmentDetail,
    updateShipmentLoadBoardViewItem,
    setShipmentDetails,
    setSelectedShipmentdetail,
    setShowShipmentActionsPopover,
    setDriverLoads,
    setShowDriverLoadModal,
    setShowAttachBillOfLadingModal,
    setIsAddDriverLoad,
    setSelectedDriverLoad,
    setDriverLoad,
    setSelectedAdditionalCharges,
    setSelectedDeductionCharges,
    setShowDeleteShipmentModal,
    setDriverListActiveKey,
    setShowDriverReVerifyRateModal,
    setCanFetchDriverList,
    setReVerifyDriverCommodityTypes,
    setCanCallShipmentListApi,
    resetLoadBoardShipmentDetails,
    setShowLoadBoardCanvas,
    setSelectedDriverIdList,
    setDriverLoadAttachments,
    setAssociatedDriverList,
    setSelectedCustomerName,
    setSelectedDriverName,
    setCurrentShipmentDetail,
    setIsShipmentStatusChanged,
    setShowOwnerOperator,
    setSelectedDriverCommodity,
    setDeductionChargeCommodityList,
} = loadBoardSlice.actions;

const LoadBoardReducer = loadBoardSlice.reducer;
export default LoadBoardReducer;
