import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { driverApi } from '../../../api/driver/driverApi';
import { ITruckingCompany } from '../../../api/interfaces/ITruckingCompany';

export interface ITruckingCompaniesInitialState {
    loading: boolean;
    truckingCompanies: ITruckingCompany[];
    createdTruckingCompanyId: number | null;
}

const initialState: ITruckingCompaniesInitialState = {
    loading: false,
    truckingCompanies: [],
    createdTruckingCompanyId: null,
};

export const fetchTruckingCompanies = createAsyncThunk(
    'truckingCompanies/fetchTruckingCompanies',
    async (_, { rejectWithValue }) => {
        try {
            return await driverApi.fetchTruckingCompanies();
        } catch {
            return rejectWithValue('Error');
        }
    },
);

export const truckingCompanySlice = createSlice({
    name: 'truckingCompany',
    initialState,
    reducers: {
        setTruckingCompanies: (
            state,
            action: PayloadAction<ITruckingCompany[]>,
        ) => {
            state.truckingCompanies = action.payload;
        },
        setCreatedTruckingCompanyId: (
            state,
            action: PayloadAction<number | null>,
        ) => {
            state.createdTruckingCompanyId = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchTruckingCompanies.pending, state => {
            state.loading = true;
            state.truckingCompanies = [];
        });
        builder.addCase(fetchTruckingCompanies.fulfilled, (state, action) => {
            state.loading = false;
            state.truckingCompanies = action.payload;
        });
        builder.addCase(fetchTruckingCompanies.rejected, state => {
            state.loading = false;
        });
    },
});

export const { setTruckingCompanies, setCreatedTruckingCompanyId } =
    truckingCompanySlice.actions;

const TruckingCompaniesReducer = truckingCompanySlice.reducer;
export default TruckingCompaniesReducer;
