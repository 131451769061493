export type SpecialCharacterURLEncoder = {
    [key: string]: string;
};

export const SpecialCharactersURLEncoder: SpecialCharacterURLEncoder = {
    '#': '%23',
    '&': '%26',
};

export enum BrokerFeeType {
    Fixed = 'Fixed',
    Percentage = 'Percentage',
}

export const BROKER_FEE_PERCENTAGE_TYPE_MAX = 50;
export const WINDOW_THRESHOLD_WIDTH = 992;
export const DEBOUNCE_DELAY = 500;
export const isLargeScreen: boolean = window.innerWidth >= 1000;
export const PERCENTAGE = 100;

export enum CustomHttpStatusCode {
    PublishToServiceBusQueueError = 707,
    ValidateDriverAssignmentToTrailer = 701,
    DuplicateEntry = 702,
}
