import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const unsavedChanges = createSlice({
    name: 'unsavedChanges',
    initialState: { hasUnsavedChanges: false, showWarningModal: false },
    reducers: {
        setUnsavedChanges: (state, action: PayloadAction<boolean>) => {
            state.hasUnsavedChanges = action.payload;
        },
        setShowWarningModal: (state, action: PayloadAction<boolean>) => {
            state.showWarningModal = action.payload;
        },
    },
});

export const { setUnsavedChanges, setShowWarningModal } =
    unsavedChanges.actions;

const UnsavedChangesReducer = unsavedChanges.reducer;
export default UnsavedChangesReducer;
